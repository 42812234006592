import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PasswordInput } from "components/input/PasswordInput";
import { isPasswordStrongEnough } from "api/user/user.endpoints";
import { useTranslation } from "react-i18next";
import { passwordValidator } from "helpers/validation";

type Props = {
  setPasswordIntermediate: (password: string) => void;
};

export const CreatePasswordField: FC<Props> = ({ setPasswordIntermediate }) => {
  const { t } = useTranslation();

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<{ password: string }>();

  const password = watch("password");

  useEffect(() => {
    if (isPasswordStrongEnough(password)) {
      setPasswordIntermediate(password);
    }
  }, [password]);

  return (
    <div>
      <PasswordInput
        register={register}
        name="password"
        label={t("Password")}
        registerOptions={passwordValidator}
        errorMessage={errors?.password?.message}
      />
      <div className="flex flex-col mt-5">
        <div className="flex items-center">
          {isPasswordStrongEnough(password) ? (
            <>
              <CheckCircleIcon className="h-5 w-5 mr-1 text-flowerSky-500" />
              <p className="text-sm text-flowerSky-700">
                {t("At least 10 characters long")}
              </p>
            </>
          ) : (
            <>
              <ExclamationCircleIcon className="h-5 w-5 mr-1 text-gray-400" />
              <p className="text-sm text-gray-400">
                {t("At least 10 characters long")}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
