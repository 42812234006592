import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import { useDeleteAssetOwner } from "api/assetOwner/assetOwner.endpoints";
import { useNavigate } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { Tooltip } from "../tags/Tooltip";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ModalContentWrapper } from "components/ModalContentWrapper";

interface Props {
  assetOwnerId: number;
}

export const DeleteAssetOwner: FC<Props> = ({ assetOwnerId = "-1" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isDeleteAssetOwnerModalOpen, setIsDeleteAssetOwnerModalOpen] =
    useState(false);

  // api
  const { mutate: deleteAssetOwner } = useDeleteAssetOwner({
    onSuccess: () => navigate(`${RouteUrl.App}/${RouteUrl.AssetOwners}`),
  });

  // submit
  const onDeleteAssetOwner = (id: number) => deleteAssetOwner(id);

  // modal close
  const onDeleteAssetOwnerModalClose = () => {
    setIsDeleteAssetOwnerModalOpen(false);
  };

  return (
    <>
      <div className="ml-5 w-4 h-5 justify-center flex">
        <div className="relative inline-block group">
          <Tooltip
            message={t(`assetOwnerAccountDetails.DeleteAssetOwner`)}
            size={"small"}
          >
            <button onClick={() => setIsDeleteAssetOwnerModalOpen(true)}>
              <TrashIcon
                className="h-5 w-5 text-red-600 hover:text-red-500"
                aria-hidden="true"
              />
            </button>
          </Tooltip>
        </div>
      </div>

      <Modal
        title={t(`assetOwnerAccountDetails.DeleteAssetOwner`)}
        leftButtonAction={onDeleteAssetOwnerModalClose}
        leftButtonText={t(`close`)}
        rightButtonText={t(`assetOwnerAccountDetails.DeleteAssetOwner`)}
        rightButtonActionHandler={() => onDeleteAssetOwner(+assetOwnerId)}
        isOpen={isDeleteAssetOwnerModalOpen}
        onClose={onDeleteAssetOwnerModalClose}
        loadingText={t(`assetOwnerAccountDetails.SendingInvitation`)}
      >
        <ModalContentWrapper>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {t(
                `assetOwnerAccountDetails.Are you sure that you want to delete this asset owner?`,
              )}
            </p>
          </div>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};
