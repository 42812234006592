import { classNames } from "helpers/classNames";
import { FC } from "react";
import { Link } from "react-router-dom";
import { NavigationItem } from "./AppWrapper.helpers";
import { PowerIcon } from "@heroicons/react/24/outline";
import {
  DoubleLeftArrowHead,
  DoubleRightArrowHead,
  FlowerHubLogo,
  FlowerSymbolLogo,
} from "styles/Icons";
import { motion } from "framer-motion";

interface Props {
  menuCollapsed: boolean;
  onMenuCollapse: () => void;
  navigation: NavigationItem[];
  logout: () => void;
}

const framerMenuBackground = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

export const DesktopMenu: FC<Props> = ({
  menuCollapsed,
  onMenuCollapse,
  navigation,
  logout,
}) => (
  <motion.div
    {...framerMenuBackground}
    className={`hidden lg:flex ${menuCollapsed ? "lg:w-14" : "lg:w-56"} lg:flex-col lg:fixed lg:inset-y-0`}
  >
    <div className="flex-1 flex flex-col min-h-0 bg-white">
      <div
        className="flex flex-shrink-0 items-center justify-end -mr-2 cursor-pointer"
        onClick={onMenuCollapse}
      >
        <img
          className="h-auto w-5"
          src={menuCollapsed ? DoubleRightArrowHead : DoubleLeftArrowHead}
          alt="Arrow"
        />
      </div>
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex flex-shrink-0 items-center justify-center">
          <img
            className={`h-auto ${menuCollapsed ? "w-7" : "w-48"}`}
            src={menuCollapsed ? FlowerSymbolLogo : FlowerHubLogo}
            alt="Flower Hub"
          />
        </div>
        <nav className="mt-5 flex-1 space-y-1 px-2">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                item.isCurrentRoute
                  ? "bg-flowerPeach-500 text-flowerPeach-900 border border-flowerPeach-900"
                  : "text-flowerGray-600 hover:text-flowerPeach-900",
                "group flex items-center px-2 py-2 text-sm font-normal rounded-md border-white",
              )}
            >
              <item.icon
                className={classNames(
                  item.isCurrentRoute
                    ? "text-flowerPeach-900"
                    : "text-flowerGray-600 group-hover:text-flowerPeach-900",
                  "mr-3 flex-shrink-0 h-6 w-6",
                )}
                aria-hidden="true"
              />
              {!menuCollapsed && item.name}
            </Link>
          ))}
        </nav>
      </div>
      <div
        onClick={() => logout()}
        className="flex-shrink-0 flex p-4 cursor-pointer"
      >
        <div className="flex-shrink-0 w-full group block cursor-pointer">
          <div className="flex items-center text-flowerGray-600 hover:text-flowerPeach-900">
            <PowerIcon
              className={
                "text-flowerGray-600  mr-3 flex-shrink-0 h-6 w-6 group-hover:text-flowerPeach-900"
              }
              aria-hidden="true"
            />
            {!menuCollapsed && (
              <div className="ml-3">
                <span className="text-sm font-medium ">Sign out</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);
