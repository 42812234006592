import React from "react";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface Statistic {
  count: number | string;
  label?: string;
}

interface DashboardTileProps {
  title: string;
  leftStat: Statistic;
  deviation?: number;
  icon: React.ReactNode;
  rightStat?: Statistic;
  showDeviation?: boolean;
}

const DashboardTile: React.FC<DashboardTileProps> = ({
  title,
  leftStat,
  deviation = 0,
  icon,
  rightStat,
  showDeviation = true,
}) => {
  const { t } = useTranslation();
  // Determine icon color based on deviation
  const deviationColor =
    deviation > 0
      ? "text-flowerGreen-300"
      : deviation == 0
        ? "text-flowerGray-200"
        : "text-flowerRed-500";
  const deviationText =
    deviation > 0
      ? t(`dashboardStatistics.HigherThanPreviousPeriod`)
      : deviation == 0
        ? t(`dashboardStatistics.EqualToPreviousPeriod`)
        : t(`dashboardStatistics.LowerThanPreviousPeriod`);

  return (
    <div className="flex flex-col p-4 gap-2 min-w-[268px] h-auto bg-white opacity-90 rounded-xl">
      <div className="flex items-start justify-between gap-2">
        <span className="text-lg font-medium text-black drop-shadow">
          {title}
        </span>
        <div className="w-6 h-6 text-flowerPeach-900">{icon}</div>
      </div>
      <div className="flex items-baseline gap-2 mb-2">
        <div className="flex items-baseline gap-1">
          <span className="text-4xl font-medium text-flowerSkyVivid-500">
            {leftStat.count}
          </span>
          <span className="text-lg font-medium text-flowerGray-400">
            {leftStat.label ?? ""}
          </span>
        </div>
        {rightStat && (
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-medium text-flowerSkyVivid-500">
              {rightStat.count}
            </span>
            <span className="text-lg font-medium text-flowerGray-400">
              {rightStat.label ?? ""}
            </span>
          </div>
        )}
      </div>
      {showDeviation && (
        <div className="flex items-center gap-1">
          <div className={`w-6 h-6 ${deviationColor}`}>
            {deviation > 0 ? (
              <ArrowTrendingUpIcon />
            ) : deviation === 0 ? (
              <ArrowLongRightIcon />
            ) : (
              <ArrowTrendingDownIcon />
            )}
          </div>
          {deviation !== 0 && (
            <span
              className={`text-lg font-medium ${deviationColor}`}
            >{`${Math.abs(deviation)}%`}</span>
          )}
          <span className="text-xs font-normal text-flowerGray-200">
            {deviationText}
          </span>
        </div>
      )}
    </div>
  );
};

export default DashboardTile;
