import { format } from "date-fns";
//import swedish from "date-fns/locale/sv"; // todo: connect the locale logic with 18next
import english from "date-fns/locale/en-GB";

export const formatDate = (date?: string | Date): string => {
  if (!date) return "-";
  return format(new Date(date), "dd MMM yyyy", {
    //locale: swedish,
    locale: english,
  });
};

export const formatDateMonth = (date?: string | Date): string => {
  if (!date) return "-";
  return format(new Date(date), "dd MMMM", {
    //locale: swedish,
    locale: english,
  });
};

export const dateToChartName = (date: string | Date): string => {
  return format(new Date(date), "MMM-yy", {
    //locale: swedish,
    locale: english,
  }).replace(".", "");
};
