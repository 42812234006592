import { ChartBarIcon } from "@heroicons/react/24/solid";
import { Panel } from "components/Panel";
import { FC } from "react";
import { getChartData } from "../../helpers/assetOwnerDashboard";
import { DesktopVersion } from "./DesktopVersion";
import { MobileVersion } from "./MobileVersion";
import MaintenanceState from "components/systemNotification/MaintenanceState";
import { ScaleUp } from "../animations/SnapUp";
import {
  useConsumption,
  useInvoices,
} from "api/assetOwner/assetOwner.endpoints";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";

interface Props {
  assetOwnerId: number;
  hasSystemNotification?: boolean;
}

export const Consumption: FC<Props> = ({
  assetOwnerId,
  hasSystemNotification = false,
}) => {
  const { t } = useTranslation();

  // Invoices
  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
  } = useInvoices(assetOwnerId);

  // Consumption Data
  const {
    data: consumption,
    isLoading: isLoadingConsumption,
    isError: isErrorConsumption,
  } = useConsumption(assetOwnerId);

  const chartData = getChartData(consumption, invoices);

  if (hasSystemNotification)
    return (
      <Panel header="Consumption">
        <MaintenanceState header="Maintenance ongoing" />
      </Panel>
    );

  const renderConsumptionPlaceholder = () => {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isErrorInvoices || isErrorConsumption}
          isLoading={isLoadingInvoices || isLoadingConsumption}
          isEmpty={
            (!isLoadingInvoices &&
              !isErrorInvoices &&
              invoices?.length === 0) ||
            (!isLoadingConsumption &&
              !isErrorConsumption &&
              consumption?.length === 0)
          }
          icon={
            <ChartBarIcon className="mx-auto h-12 w-12 text-flowerblue-400" />
          }
          errorMessage={t(`graph.error`)}
          emptyDataMessage={t(`graph.Nothing to display`)}
          loaderSize="medium"
        />
      </div>
    );
  };

  return (
    <ScaleUp>
      {chartData.length === 0 ? (
        renderConsumptionPlaceholder()
      ) : (
        <>
          {/* Visible on screens < 640px */}
          <MobileVersion data={chartData} />
          {/* Visible on screens >= 640 px */}
          <DesktopVersion data={chartData} />
        </>
      )}
    </ScaleUp>
  );
};
