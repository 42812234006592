import { CreateInstallerDTO } from "api/installer/installer.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox } from "components/input/Checkbox";

interface Props {
  register: UseFormRegister<CreateInstallerDTO>;
  errors: Partial<FieldErrorsImpl<CreateInstallerDTO>>;
}

export const AddInstallerForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`installerList.Company name`)}
        name="name"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      <TextInput
        label={t("installerList.Organization number")}
        name="organizationNumber"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput
        label={t("installerList.Admin email address")}
        name="firstUserEmail"
        register={register}
        registerOptions={emailValidator}
        errorMessage={errors?.firstUserEmail?.message}
        type="email"
      />
      <Checkbox
        register={register}
        item={{
          label: t("installerList.This installer can invite asset owners"),
          name: "canInviteAssetOwners",
        }}
      />
    </ModalContentWrapper>
  );
};
