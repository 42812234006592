import {
  GetAssetOwnerProfileResponseDTO,
  GetAssetOwnerItemResponseDTO,
} from "api/assetOwner/assetOwner.dto";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb } from "store/menu.store";

// Question: necessary to have a function for that?
export const getAssetOwnerName = ({
  firstName,
  lastName,
}: GetAssetOwnerItemResponseDTO | GetAssetOwnerProfileResponseDTO) =>
  `${firstName} ${lastName}`;

export const getAssetOwnerListBreadcrumb = (): BreadCrumb => ({
  label: "Asset owners",
  to: getAssetOwnerListLink(),
});

export const getAssetOwnerListLink = (): string =>
  `${RouteUrl.App}/${RouteUrl.AssetOwners}`;
