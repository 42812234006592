import { UpdateAssetOwnerRequestDTO } from "api/assetOwner/assetOwner.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { PostalCodeInput } from "components/input/PostalCodeInput";
import { TextInput } from "components/input/TextInput";
import { noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";

interface Props {
  register: UseFormRegister<UpdateAssetOwnerRequestDTO>;
  errors: Partial<FieldErrorsImpl<UpdateAssetOwnerRequestDTO>>;
}
export const EditAssetOwnerProfileForm: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label={"First name"}
        name="firstName"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.firstName?.message}
      />
      <TextInput
        label={"Last name"}
        name="lastName"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.lastName?.message}
      />
      <TextInput
        label={"Address"}
        name="address.street"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.address?.message}
      />
      <PostalCodeInput
        label={"Postal code"}
        name="address.postalCode"
        register={register}
        errorMessage={errors.address?.postalCode?.message}
      />
      <TextInput
        label={"City"}
        name="address.city"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.address?.city?.message}
      />
      <TextInput
        label={"Phone"}
        name="phone"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.phone?.message}
      />
    </ModalContentWrapper>
  );
};
