import { AssetOwnerStatusTag } from "components/tags/AssetOwnerStatusTag";
import { FC, useMemo } from "react";
/* cspell:disable-next-line */
import { TableOptions } from "@tanstack/react-table";
import {
  Filter,
  filterFunction,
  getUniqueFilterTags,
} from "components/filters/Filters";
import { TanStackTable } from "components/table/TanStackTable";
import { CompensationEligibilityStatusTag } from "components/tags/CompensationEligibilityStatusTag";
import { GetAssetOwnerItemResponseDTO } from "api/assetOwner/assetOwner.dto";
import { CompensationStatusMessage } from "enums/assetOwnerCompensationStatus.enum";
import { FlowerHubStatusTag } from "components/tags/FlowerHubStatusTag";
import { FlowerHubStatusDTO } from "api/asset/asset.dto";
import { Role } from "enums/role.enum";
import { isFlower } from "api/user/user.type";

interface Props {
  userRole: Role;
  assetOwners?: GetAssetOwnerItemResponseDTO[];
  onAssetOwnerClick: (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => void;
}
export const DesktopList: FC<Props> = ({
  userRole,
  assetOwners = [],
  onAssetOwnerClick,
}) => {
  const columns: TableOptions<GetAssetOwnerItemResponseDTO>["columns"] =
    useMemo(
      () => [
        {
          accessorKey: "name",
          accessorFn: (row: GetAssetOwnerItemResponseDTO) =>
            `${row.firstName} ${row.lastName}`,
          header: "Name",
          filterFn: "includesString",
        },
        {
          accessorKey: "accountStatus",
          header: "Account",
          cell: (row) => <AssetOwnerStatusTag status={row.getValue()} />,
          filterFn: filterFunction,
        },
        {
          accessorFn: (row: GetAssetOwnerItemResponseDTO) =>
            row.asset?.assetModel &&
            `${row.asset.assetModel.manufacturer} ${row.asset.assetModel.name}`,
          header: "Asset Model",
          filterFn: "includesString",
        },
        {
          accessorFn: (row: GetAssetOwnerItemResponseDTO) =>
            row.asset?.serialNumber && row.asset.serialNumber,
          header: "Serial number",
          filterFn: "includesString",
        },
        {
          accessorKey: "asset",
          accessorFn: (row: GetAssetOwnerItemResponseDTO) =>
            row?.asset?.flowerHubStatus?.status,
          header: "Flower Hub ",
          cell: (row) => {
            if (!row.row.original?.asset?.id) {
              // if asset owner is not associate with a asset , cell will be empty
              return null;
            }
            return (
              <FlowerHubStatusTag
                flowerHubStatus={
                  row.row.original?.asset?.flowerHubStatus as FlowerHubStatusDTO
                }
                isFlowerUser={isFlower(userRole)}
              />
            );
          },
          filterFn: filterFunction,
        },
        {
          accessorKey: "compensation",
          accessorFn: (row: GetAssetOwnerItemResponseDTO) =>
            row?.compensation?.status,
          header: "Compensation ",
          cell: (row) => (
            <CompensationEligibilityStatusTag
              status={row.getValue()}
              message={
                row.row.original?.compensation
                  ?.message as CompensationStatusMessage
              }
            />
          ),
          filterFn: filterFunction,
        },
      ],
      [assetOwners],
    );

  const filters: Filter[] = [
    {
      id: "accountStatus",
      name: "Account",
      options: getUniqueFilterTags({
        data: assetOwners,
        columnName: "accountStatus",
      }),
    },
    {
      id: "asset",
      name: "Flower Hub",
      options: getUniqueFilterTags({
        data: assetOwners,
        columnName: "asset",
        firstNestedProperty: "flowerHubStatus",
        secondNestedProperty: "status",
      }),
    },
    {
      id: "compensation",
      name: "Compensation",
      options: getUniqueFilterTags({
        data: assetOwners,
        columnName: "compensation",
        firstNestedProperty: "status",
      }),
    },
  ];

  return (
    <div className="block">
      <TanStackTable
        columns={columns}
        data={assetOwners}
        filters={filters}
        onSelectRow={(row: GetAssetOwnerItemResponseDTO) =>
          onAssetOwnerClick(row.id, row.installerId, row.distributorId)
        }
      />
    </div>
  );
};
