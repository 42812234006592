import { Breakpoint } from "enums/breakpoint.enum";
import { ScreenWidth } from "enums/screenWidth.enum";
import { useWindowSize } from "./useWindowSize";

export const useScreenWidth = (): ScreenWidth => {
  const { width } = useWindowSize();
  const widthAsNumber = width ?? 0;

  if (widthAsNumber > Breakpoint.LG) {
    return ScreenWidth.Desktop;
  } else if (widthAsNumber > Breakpoint.SM) {
    return ScreenWidth.Tablet;
  } else {
    return ScreenWidth.Mobile;
  }
};
