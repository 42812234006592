import { FC } from "react";
import { OpacityTransition } from "components/animations/SnapUp";
import { StepHeader } from "components/installationFlow/steps/StepHeader";
import { LargeLoader } from "components/loaders/LargeLoader";
import { useTranslation } from "react-i18next";

type ConnectingProps = {
  isConnected: boolean;
};
export const ConnectingStep: FC<ConnectingProps> = ({ isConnected }) => {
  const { t } = useTranslation();

  return (
    <OpacityTransition>
      <StepHeader>
        {isConnected
          ? t(`batteryOverview.Connected to Flower Hub!`)
          : t(`batteryOverview.Connecting to Flower Hub...`)}
      </StepHeader>
      {!isConnected && (
        <div className="flex items-center justify-center mt-4 mb-8">
          <LargeLoader />
        </div>
      )}
      {!isConnected && (
        <div className="flex items-center justify-center mt-4 mb-8">
          <p className="text-base text-gray-800">
            {t(`batteryOverview.This might take a couple of minutes`)}
          </p>
        </div>
      )}
    </OpacityTransition>
  );
};
