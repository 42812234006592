import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteInstallerInvitation } from "api/installerInvitation/installerInvitation.endpoints";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import { useQueryClient } from "react-query";
import Modal from "components/Modal";
import SecondaryButton from "components/buttons/SecondaryButton";
import { DeleteInstallerConfirmation } from "./DeleteInstallerConfirmation";

interface Props {
  installerId: number;
  invitationId: number;
}

export const DeleteInstallerInvitation: FC<Props> = ({
  installerId,
  invitationId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isDeleteInstallerModalOpen, setIsInstallerModalOpen] = useState(false);

  // api
  const { mutate: deleteInstaller, isSuccess } = useDeleteInstallerInvitation({
    onSuccess: () => {
      console.log("success and invalidate query, installer id : ", installerId);
      queryClient.invalidateQueries([InstallerQueryKeys.GetUsers, installerId]);
    },
  });

  // submit
  const onDeleteInstaller = () => deleteInstaller(invitationId);

  // modal close
  const onDeleteInstallerModalClose = () => {
    setIsInstallerModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onDeleteInstallerModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <SecondaryButton
        size="small"
        onClick={() => setIsInstallerModalOpen(true)}
      >
        {t(`installer.Delete installer`)}
      </SecondaryButton>

      <Modal
        title={t(`installer.Delete installer`)}
        leftButtonAction={onDeleteInstallerModalClose}
        leftButtonText={t(`close`)}
        rightButtonActionHandler={() => onDeleteInstaller()}
        isOpen={isDeleteInstallerModalOpen}
        onClose={onDeleteInstallerModalClose}
        loadingText={t(`installer.Sending invitation`)}
      >
        <DeleteInstallerConfirmation />
      </Modal>
    </>
  );
};
