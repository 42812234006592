import CustomPlaceholder from "components/CustomPlaceholder";
import {
  generateProgressList,
  getProgressStepColor,
} from "helpers/flowerHubStatus";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "components/tags/Badge";
import { Panel } from "components/Panel";
import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import { isFlower } from "api/user/user.type";
import userStore, { getUserRole } from "store/user.store";
import { useFlowerHubProgress } from "api/flowerHub/flowerHub.endpoints";
import { ProgressListItem } from "./progressListItem/ProgressListItem";

interface Props {
  serialNumber: string;
}

export const FlowerHubProgress: FC<Props> = ({ serialNumber }) => {
  const { t } = useTranslation();
  const userRole = userStore(getUserRole);

  const { data, isError, isLoading } = useFlowerHubProgress(serialNumber);

  const progressList = useMemo(() => {
    if (data) {
      return generateProgressList(data.step, data.message);
    }
  }, [data]);

  return (
    <Panel
      header={t(`flowerHubProgress.Title`)}
      infoText={t(`flowerHubProgress.Info`)}
    >
      <nav className="mx-auto max-w-7xl" aria-label="Progress">
        {isLoading || !data || isError ? (
          <CustomPlaceholder
            isError={isError}
            isLoading={isLoading}
            isEmpty={!isLoading && !isError && !data}
            icon={<PresentationChartLineIcon />}
            errorMessage={t(`flowerHubProgress.UnableToLoad`)}
          />
        ) : (
          <ol
            role="list"
            className="mt-3 overflow-hidden rounded-md lg:grid lg:grid-cols-6"
          >
            {progressList?.map((step, stepIdx) => (
              <ProgressListItem
                key={stepIdx}
                step={step}
                stepIdx={stepIdx}
                listLength={progressList.length}
              />
            ))}
          </ol>
        )}
        {data?.message && (
          <div className="flex flex-col justify-center items-center mt-4 ">
            {isFlower(userRole) && (
              <div className="w-fit">
                <Badge color={getProgressStepColor(data)} text={data.message} />
              </div>
            )}

            <span
              className={`flex text-sm justify-center text-center leading-6 mt-2 text-${getProgressStepColor(data)}-600`}
            >
              {isFlower(userRole)
                ? t([
                    `flowerHubProgress.flowerUserProgressMessage.${data.message}`,
                    "flowerHubProgress.flowerUserProgressMessage.FallbackMessage",
                  ])
                : t([
                    `flowerHubProgress.installerProgressMessage.${data.message}`,
                    "flowerHubProgress.installerProgressMessage.FallbackMessage",
                  ])}
            </span>
          </div>
        )}
      </nav>
    </Panel>
  );
};
