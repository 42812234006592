import { classNames } from "helpers/classNames";
import { FC } from "react";

interface FlowerHubStatProps {
  name: string;
  data?: string;
  list?: string[];
}
export const FlowerHubStatisticContainer: FC<FlowerHubStatProps> = ({
  name,
  data,
  list = [],
}) => {
  return (
    <div
      key={name}
      className={classNames(
        "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-900/4 px-8 py-10 sm:px-8 xl:px-8",
      )}
    >
      <dt className="text-sm font-medium leading-6 text-gray-500">{name}</dt>
      {data ? (
        <DataDisplay data={data} />
      ) : list.length > 0 ? (
        <ListDataDisplay list={list} />
      ) : (
        <NoDataDisplay />
      )}
    </div>
  );
};

const DataDisplay: FC<{ data: string }> = ({ data }) => (
  <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-gray-900 break-all">
    {data}
  </dd>
);

const ListDataDisplay: FC<{ list: string[] }> = ({ list }) => (
  <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-gray-900">
    {list.map((item) => (
      <div
        key={item}
        className="flex items-center justify-end gap-x-2 sm:justify-start"
      >
        <div className="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
          <div className="h-1.5 w-1.5 rounded-full bg-current" />
        </div>
        <div className="hidden text-xl sm:block">{item}</div>
      </div>
    ))}
  </dd>
);

const NoDataDisplay: FC = () => <dd className="w-full flex-none">{"-"}</dd>;
