import { Role } from "enums/role.enum";

export type CreateDistributorDTO = {
  name: string;
  organizationNumber: string;
  firstUserEmail: string;
};

export const getEmptyCreateDistributorDTO = (): CreateDistributorDTO => ({
  name: "",
  organizationNumber: "",
  firstUserEmail: "",
});

export type UpdateDistributorDTO = {
  name: string;
  organizationNumber: string;
  address?: string;
  postalCode?: string;
  city?: string;
  giroNumber?: string;
};

export type DeleteInvitationDTO = {
  distributorId: number;
  invitationId: number;
};

export type GetLatestInstallersItemDTO = {
  installerId: number;
  name: string;
  organization: string;
  role: Role;
  createdAt: Date;
};
