import { FC } from "react";
import { Badge, BadgeColor, BadgeSize } from "./Badge";

import { Tooltip } from "./Tooltip";
import { ZavannSiteStateCategory } from "../../enums/zavannSiteStateCategory.enum";
import { useTranslation } from "react-i18next";

interface Props {
  stateCategory: ZavannSiteStateCategory;
  stateId: number;
  stateReasonId?: number;
  size?: BadgeSize;
  withTooltip?: boolean;
}

export const ElectricityAgreementTag: FC<Props> = ({
  stateCategory,
  stateId,
  stateReasonId,
  size = "small",
  withTooltip = true,
}) => {
  const { t } = useTranslation();

  const electricAgreementTagColor =
    getElectricityAgreementTagColor(stateCategory);

  return (
    <div className="relative inline-block group">
      <div className="relative inline-block group">
        {withTooltip ? (
          <Tooltip
            message={
              stateReasonId
                ? t(`zavannSiteState.${stateId}`) +
                  ": " +
                  t(`zavannSiteStateReason.${stateReasonId}`)
                : t(`zavannSiteState.${stateId}`)
            }
            color={electricAgreementTagColor}
            position="right"
          >
            <Badge
              color={electricAgreementTagColor}
              text={stateCategory ? stateCategory : "Unknown"}
              size={size}
            />
          </Tooltip>
        ) : (
          <Badge
            color={electricAgreementTagColor}
            text={stateCategory ? stateCategory : "Unknown"}
            size={size}
          />
        )}
      </div>
    </div>
  );
};

const getElectricityAgreementTagColor = (
  stateCategory: ZavannSiteStateCategory,
): BadgeColor => {
  switch (stateCategory) {
    case ZavannSiteStateCategory.Active:
      return "green";
    case ZavannSiteStateCategory.InProgress:
      return "blue";
    case ZavannSiteStateCategory.AwaitingTermination:
      return "yellow";
    case ZavannSiteStateCategory.Terminated:
      return "gray";
    case ZavannSiteStateCategory.Error:
      return "red";
    default:
      return "gray";
  }
};
