import { animated, useTransition } from "react-spring";

interface Props {
  children: React.ReactNode;
  settings: object;
  isVisible?: boolean;
  className?: string;
}

export const Transition: React.FC<Props> = ({
  children,
  settings,
  isVisible = true,
  className = undefined,
}) => {
  const transitions = useTransition(isVisible, settings);
  return transitions(
    (styles, item) =>
      item && (
        <animated.div style={styles} className={className}>
          {children}
        </animated.div>
      ),
  );
};
