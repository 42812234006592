import { PageWrapper } from "components/PageWrapper";
import { isEmptyArray } from "helpers/array";
import { FC } from "react";

import { isFlower } from "api/user/user.type";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddDistributor } from "./AddDistributor";
import { useDistributors } from "api/distributor/distributor.endpoints";
import { Distributor } from "api/distributor/distributor.type";
import { Table, THead, TR, TH, TBody, TD } from "components/table";

interface Props {
  userRole: Role;
}

export const DistributorList: FC<Props> = ({ userRole }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // get distributor list
  const { data: distributors, isLoading, isError } = useDistributors();

  const onDistributorClick = (id: number) => navigate(`${id}`);

  if (isLoading || isError) {
    return (
      <CustomPlaceholder
        isEmpty={false}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`distributorList.Error`)}
        loaderSize="page"
      />
    );
  }

  if (!isLoading && isEmptyArray([distributors])) {
    return (
      <>
        <CustomPlaceholder
          isEmpty={true}
          emptyDataMessage={t(`distributorList.Empty`)}
          isLoading={false}
          isError={false}
          icon={<UserIcon />}
        />

        {isFlower(userRole) && <AddDistributor isCentered />}
      </>
    );
  }

  return (
    <PageWrapper>
      <div>
        <div className="sm:flex sm:items-center mb-4">
          <div className="sm:flex-auto">
            <AddDistributor />
            <p className="text-gray-900 text-sm">
              {t(`distributorList.A list of our existing distributors.`)}
            </p>
          </div>
        </div>

        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t("distributorList.Company")}</TH>
              <TH>{t("distributorList.Organization number")}</TH>
              <TH>{t("distributorList.City")}</TH>
            </TR>
          </THead>
          <TBody>
            {distributors?.map(
              ({ id, name, organizationNumber, city }: Distributor) => (
                <TR key={id} onClick={() => onDistributorClick(id)}>
                  <TD addLeftMargin>{name}</TD>
                  <TD>{organizationNumber}</TD>
                  <TD>{city}</TD>
                </TR>
              ),
            )}
          </TBody>
        </Table>
      </div>
    </PageWrapper>
  );
};
