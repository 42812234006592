import {
  useDistributor,
  useUpdateDistributor,
} from "api/distributor/distributor.endpoints";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import { NotFound } from "components/NotFound";
import { PageLoader } from "components/loaders/PageLoader";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditDistributorModal } from "./EditDistributorModal";
import { AccountDetails } from "./AccountDetails";
import { UsersTab } from "./UsersTab";
import { capitalizeFirstLetter } from "helpers/string";
import { BreadCrumb, menuStore } from "store/menu.store";
import { RouteUrl } from "routing/routeUrl.enum";
import { getDistributorListBreadcrumb } from "../distributorList/DistributorListPage";
import { Installers } from "./Installers";
import { UpdateDistributorDTO } from "api/distributor/distributor.dto";
import { DashboardStatistics } from "../../../components/statistics/DashboardStatistics";
import { LatestInstallerList } from "components/installer/LatestInstallerList";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const DASHBOARD_TAB = "dashboard";
const ORGANIZATION_TAB = "organization";
const USERS_TAB = "users";
const INSTALLERS_TAB = "installers";

export default function DistributorPage() {
  const params = useParams();
  const distributorId: string = params?.distributorId || "-1";

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || DASHBOARD_TAB;
  const { setBreadCrumbs } = menuStore();

  // Get distributor
  const {
    data: distributor,
    isLoading,
    isError,
  } = useDistributor(+distributorId);
  /**
   * Update distributor
   */
  const [isEditDistributorModalOpen, setIsEditDistributorModalOpen] =
    useState<boolean>(false);

  const {
    mutate: updateDistributor,
    isLoading: isUpdating,
    isSuccess,
  } = useUpdateDistributor(+distributorId);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateDistributorDTO>();

  const onSubmit = handleSubmit((data: UpdateDistributorDTO) =>
    updateDistributor(data),
  );

  useEffect(() => {
    if (distributor) {
      setBreadCrumbs([
        getDistributorListBreadcrumb(),
        getDistributorBreadcrumb({
          id: distributor.id,
          name: distributor.name,
        }),
      ]);
      // This populates the form with the existing values of the distributor every time the distributor changes,
      // I.E. when we get a new version from server
      reset(distributor);
    }
  }, [distributor]);

  useEffect(() => {
    if (isSuccess) {
      onEditDistributorModalClose();
    }
  }, [isSuccess]);

  const onEditDistributorModalClose = () => {
    setIsEditDistributorModalOpen(false);
  };

  // Navigate to installer page
  const navigate = useNavigate();
  const onInstallerClick = (id: number) => navigate(`${id}`);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!distributor) {
    return <NotFound link="/" />;
  }

  if (isError) {
    return <h1>Error</h1>;
  }

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            param: DASHBOARD_TAB,
            label: capitalizeFirstLetter(DASHBOARD_TAB),
            content: (
              <>
                <DashboardStatistics distributorId={+distributorId} />
                <LatestInstallerList distributorId={+distributorId} />
              </>
            ),

            isSelected: currentTab === DASHBOARD_TAB,
          },
          {
            param: ORGANIZATION_TAB,
            label: capitalizeFirstLetter(ORGANIZATION_TAB),
            content: <AccountDetails distributor={distributor} />,
            isSelected: currentTab === ORGANIZATION_TAB,
            button: (
              <SecondaryButton
                size="small"
                onClick={() => setIsEditDistributorModalOpen(true)}
              >
                <PencilSquareIcon className="h-5 w-5 mr-1" /> Edit
              </SecondaryButton>
            ),
          },
          {
            param: INSTALLERS_TAB,
            label: capitalizeFirstLetter(INSTALLERS_TAB),
            content: (
              <Installers
                installers={distributor.installers}
                onInstallerClick={onInstallerClick}
              />
            ),
            isSelected: currentTab === INSTALLERS_TAB,
          },
          {
            param: USERS_TAB,
            label: capitalizeFirstLetter(USERS_TAB),
            content: (
              <UsersTab
                users={distributor.users ?? []}
                invitations={distributor.invitations ?? []}
              />
            ),
            isSelected: currentTab === USERS_TAB,
          },
        ]}
        setTab={setTab}
      />
      <Modal
        isOpen={isEditDistributorModalOpen}
        rightButtonActionHandler={onSubmit}
        title={`Edit ${distributor.name}`}
        rightButtonText="Save"
        onClose={onEditDistributorModalClose}
        isLoading={isUpdating}
        leftButtonAction={onEditDistributorModalClose}
        leftButtonText="Close"
      >
        <EditDistributorModal register={register} errors={errors} />
      </Modal>
    </>
  );
}

export const getDistributorBreadcrumb = ({
  id,
  name,
}: {
  id: number;
  name: string;
}): BreadCrumb => ({
  label: name,
  to: `${RouteUrl.App}/${RouteUrl.Distributors}/${id}`,
  iconType: "distributor",
});
