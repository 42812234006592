import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import { Distributor } from "./distributor.type";
import {
  CreateDistributorDTO,
  GetLatestInstallersItemDTO,
  UpdateDistributorDTO,
} from "./distributor.dto";
import { DistributorInvitation } from "api/distributorInvitation/distributorInvitation.type";
import { User } from "api/user/user.type";

const ENDPOINT = "/distributor";

export enum DistributorQueryKeys {
  GetOne = "distributor-get-one",
  GetAll = "distributor-get-all",
  GetUsers = "distributor-get-users",
  GetLatestInstallers = "distributor-get-latest-installers",
}

export const useDistributors = () =>
  useQuery(
    [DistributorQueryKeys.GetAll],
    async (): Promise<Distributor[]> => (await api.get(ENDPOINT)).data,
  );

export const useDistributor = (id: number) =>
  useQuery(
    [DistributorQueryKeys.GetOne, id],
    async (): Promise<Distributor> => (await api.get(`${ENDPOINT}/${id}`)).data,
  );

export const useCreateDistributor = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateDistributorDTO): Promise<Distributor> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([DistributorQueryKeys.GetAll]);
        notification.info(`${data.name} created`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useUpdateDistributor = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: UpdateDistributorDTO): Promise<Distributor> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([DistributorQueryKeys.GetOne, data.id]);
        notification.info(`${data.name} updated`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useDistributorUsers = (id: number) =>
  useQuery(
    [DistributorQueryKeys.GetUsers, id],
    async (): Promise<DistributorUsersResponse> =>
      (await api.get(`${ENDPOINT}/${id}/user`)).data,
    { initialData: { users: [], invitations: [] } },
  );

type DistributorUsersResponse = {
  users: User[];
  invitations: DistributorInvitation[];
};

export const useGetLatestInstallers = (distributorId?: number) => {
  return useQuery(
    [DistributorQueryKeys.GetLatestInstallers, distributorId],
    async (): Promise<GetLatestInstallersItemDTO[]> =>
      (await api.get(`${ENDPOINT}/${distributorId}/dashboard/latest-installer`))
        .data,
    {
      enabled: !!distributorId,
    },
  );
};
