import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BadgeSize } from "components/tags/Badge";
import { Tooltip } from "components/tags/Tooltip";
import { FlowerHubStatusDTO } from "api/asset/asset.dto";
import { getStatusColor } from "helpers/flowerHubStatus";

interface Props {
  flowerHubStatus: FlowerHubStatusDTO;
  isFlowerUser?: boolean;
  size?: BadgeSize;
}

export const FlowerHubStatusTag: FC<Props> = ({
  flowerHubStatus,
  isFlowerUser = false,
  size = "small",
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center min-w-40">
      <div className="absolute group flex flex-col items-center justify-center">
        <Tooltip
          message={
            isFlowerUser
              ? t([
                  `flowerHubProgress.flowerUserProgressMessage.${flowerHubStatus.message}`,
                  "flowerHubProgress.flowerUserProgressMessage.FallbackMessage",
                ])
              : t([
                  `flowerHubProgress.installerProgressMessage.${flowerHubStatus.message}`,
                  "flowerHubProgress.installerProgressMessage.FallbackMessage",
                ])
          }
          color={getStatusColor(flowerHubStatus.status)}
        >
          <Badge
            color={getStatusColor(flowerHubStatus.status)}
            text={flowerHubStatus.status}
            size={size}
          />
        </Tooltip>
      </div>
    </div>
  );
};
