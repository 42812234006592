import { FC } from "react";
import { SimpleButton } from "components/buttons/SimpleButton";
import { DateTimePicker } from "../../../components/input/DateTimePicker";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ElectricityContractUseFormProps {
  startDate: Date;
}
interface Props {
  onStartDateChosen: (arg: Date) => void;
  startDate: Date;
  onBackButtonClicked: () => void;
}

export const ElectricityContract: FC<Props> = ({
  onStartDateChosen,
  onBackButtonClicked,
  startDate,
}) => {
  const { t } = useTranslation();
  const { handleSubmit: handleStartDateSubmit, control } =
    useForm<ElectricityContractUseFormProps>({
      defaultValues: { startDate: startDate },
      mode: "onSubmit",
    });

  const onStartDateSubmit = handleStartDateSubmit(
    (data: ElectricityContractUseFormProps) =>
      onStartDateChosen(data.startDate),
  );

  const currentDate = new Date();

  return (
    <div className="space-y-8 sm:w-fit bg-white px-6 py-8">
      <div className="space-y-8 sm:space-y-5 sm:grid sm:grid-cols-6 sm:gap-2">
        <div className="space-y-6 sm:space-y-5 sm:col-span-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("Electricity contract")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-600 leading-6">
            {t("To use Flower Hub")}{" "}
            <a
              className="text-sm font-medium text-flowerSky-800 hover:text-flowerSky-500"
              href="https://flowerhub.se/elavtal"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("here")}
            </a>
            .
          </p>
          <div className={"p-3 bg-gray-50 rounded-lg shadow"}>
            <p className="mt-1 max-w-2xl text-sm text-gray-600 leading-6">
              {t("At the end of this registration flow")}
            </p>
            <p className="mt-1 max-w-2xl text-sm text-red-600 leading-6 mb-4">
              {t("Important! If you select a start date that conflicts")}
            </p>
            <DateTimePicker
              label={t("Start date for electricity contract")}
              name="startDate"
              control={control}
              minDate={
                new Date(currentDate.setMonth(currentDate.getMonth() + 1))
              }
              maxDate={
                new Date(currentDate.setMonth(currentDate.getMonth() + 1))
              }
              showTimeSelect={false}
              validation={{
                required: "Start date is required",
                validate: (value: string) => value !== "",
              }}
            />
          </div>
          <p className="mt-1 max-w-2xl text-sm text-gray-600 leading-6">
            Genom att gå vidare och sedan signera accepterar du elavtalets{" "}
            <a
              className="text-sm font-medium text-flowerSky-800 hover:text-flowerSky-500"
              href="https://flowerhub.se/elavtal-villkor"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("terms")}
            </a>
            .
          </p>
        </div>
        <div className="sm:col-span-1 sm:mt-0">
          <SimpleButton
            onClick={onBackButtonClicked}
            text={"Tillbaka"}
            style={"secondary"}
          />
        </div>
        <div className="sm:col-span-5 sm:mt-0">
          <SimpleButton
            onClick={onStartDateSubmit}
            text={"Jag vill byta elavtal"}
            style={"primary"}
          />
        </div>
      </div>
    </div>
  );
};
