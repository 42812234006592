import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { Invitation } from "types/invitation.type";
import { isEmptyArray } from "helpers/array";
import EmptyState from "components/EmptyState";
import { Shadow } from "components/Shadow";
import { Badge } from "components/tags/Badge";
import { User, getRoleName } from "api/user/user.type";

interface Props {
  users?: User[];
  invitations?: Invitation[];
}
export const UsersTab: FC<Props> = ({ users = [], invitations = [] }) => {
  return isEmptyArray(users) && isEmptyArray(invitations) ? (
    <EmptyState header="No users" subHeader="No users have been added yet" />
  ) : (
    <Shadow>
      <Table>
        <THead>
          <TR>
            <TH isFirstColumn>Email</TH>
            <TH>Name</TH>
            <TH>Account type</TH>
            <TH>Status</TH>
          </TR>
        </THead>
        <TBody>
          {users.map(({ email, role, name }: User) => (
            <TR key={email}>
              <TD addLeftMargin>{email}</TD>
              <TD>{name}</TD>
              <TD>{getRoleName(role)}</TD>
              <TD>
                <Badge color="green" text="Account created" />
              </TD>
            </TR>
          ))}
          {invitations.map(({ email, id, role, name }: Invitation) => (
            <TR key={id}>
              <TD addLeftMargin>{email}</TD>
              <TD>{name}</TD>
              <TD>{getRoleName(role)}</TD>
              <TD>
                <Badge color="blue" text="Invited" />
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
    </Shadow>
  );
};
