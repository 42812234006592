import { UpdateInstallerDTO } from "api/installer/installer.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { PostalCodeInput } from "components/input/PostalCodeInput";
import { TextInput } from "components/input/TextInput";
import { noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<UpdateInstallerDTO>;
  errors: Partial<FieldErrorsImpl<UpdateInstallerDTO>>;
}
export const EditInstallerForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`installer.Company name`)}
        name="name"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      <TextInput
        label={t(`installer.Organization number`)}
        name="organizationNumber"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput
        label={t(`installer.Address`)}
        name="address"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.address?.message}
      />
      <PostalCodeInput
        label={t(`installer.Postal code`)}
        name="postalCode"
        register={register}
        errorMessage={errors?.postalCode?.message}
      />
      <TextInput
        label={t(`installer.City`)}
        name="city"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.city?.message}
      />
      <TextInput
        label={t(`installer.Bankgiro / postgiro`)}
        name="giroNumber"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.giroNumber?.message}
      />
    </ModalContentWrapper>
  );
};
