import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";
import { Tooltip } from "../tags/Tooltip";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useResendInvitation } from "api/assetOwnerInvitation/assetOwnerInvitation.endpoints";
import { useNotification } from "hooks/useNotification";

interface Props {
  assetOwnerId: number;
  installerId: number;
  oldInvitationId?: number;
}

export const ResentAssetOwnerInvitation: FC<Props> = ({
  assetOwnerId,
  installerId,
  oldInvitationId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotification();

  // api
  const { mutate: resendInvitation } = useResendInvitation({
    onSuccess: async () => {
      queryClient
        .invalidateQueries([AssetOwnerQueryKeys.GetOne, assetOwnerId])
        .then(() =>
          notification.info(t(`assetOwnerAccountDetails.InvitationResent`)),
        );
    },
  });

  // submit
  const onResendInvitation = () => {
    if (oldInvitationId && installerId) {
      resendInvitation({ oldInvitationId, installerId });
    }
  };

  return (
    <div className="ml-5 w-4 h-5 justify-center flex">
      <div className="relative inline-block group">
        <Tooltip
          message={t(`assetOwnerAccountDetails.ResentInvitation`)}
          size={"small"}
        >
          <button onClick={onResendInvitation}>
            <ArrowPathIcon
              className="h-5 w-5 text-flowerblue-600 hover:text-flowerblue-500"
              aria-hidden="true"
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
