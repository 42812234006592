import { PageWrapper } from "components/PageWrapper";
import { isEmptyArray } from "helpers/array";
import { FC } from "react";

import { isDistributor } from "api/user/user.type";
import { useInstallers } from "api/installer/installer.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableOptions } from "@tanstack/react-table";
import { Installer } from "api/installer/installer.type";
import {
  filterFunction,
  Filter,
  getUniqueFilterTags,
} from "components/filters/Filters";
import { TanStackTable } from "components/table/TanStackTable";
import { Badge } from "components/tags/Badge";
import {
  InstallerStatus,
  InstallerCanInviteFilterStatus,
} from "enums/installerStatus.enum";
import { AddInstaller } from "./AddInstaller";
import { getInstallerPageRoute } from "helpers/installer";

interface Props {
  userRole: Role;
  distributorId: number;
}

export const InstallerList: FC<Props> = ({ userRole, distributorId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // get installer list
  const { data: installers, isLoading, isError } = useInstallers();

  const onInstallerClick = (installerId: number, distributorId: number) =>
    navigate(getInstallerPageRoute(userRole, installerId, distributorId));

  if (isLoading || isError) {
    return (
      <CustomPlaceholder
        isEmpty={false}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`installerList.Error`)}
        loaderSize="page"
      />
    );
  }

  if (!isLoading && isEmptyArray([installers])) {
    return (
      <>
        <CustomPlaceholder
          isEmpty={true}
          emptyDataMessage={t(`installerList.Empty`)}
          isLoading={false}
          isError={false}
          icon={<UserIcon />}
        />

        {isDistributor(userRole) && (
          <AddInstaller distributorId={distributorId} isCentered />
        )}
      </>
    );
  }

  const columns: TableOptions<Installer>["columns"] = [
    {
      header: t(`installerList.Company`),
      accessorKey: "name",
      enableColumnFilter: true,
      filterFn: "includesString",
    },
    {
      header: t(`installerList.Organization number`),
      accessorKey: "organizationNumber",
      enableColumnFilter: true,
      filterFn: "includesString",
    },
    {
      header: t(`installerList.City`),
      accessorKey: "city",
      enableColumnFilter: true,
      filterFn: "includesString",
    },
    {
      header: t(`installerList.Status`),
      accessorKey: "status",
      cell: (row) => (
        <Badge
          text={row.getValue()}
          color={row.getValue() === InstallerStatus.Pending ? "blue" : "green"}
        />
      ),
      filterFn: filterFunction,
      enableColumnFilter: true,
    },
    {
      header: t(`installerList.Can invite asset owners`),
      accessorKey: "canInviteAssetOwners",
      accessorFn: (row: Installer) =>
        row.canInviteAssetOwners
          ? InstallerCanInviteFilterStatus.Yes
          : InstallerCanInviteFilterStatus.No,
      cell: (row) => (
        <Badge
          text={row.getValue()}
          color={
            row.getValue() === InstallerCanInviteFilterStatus.Yes
              ? "green"
              : "red"
          }
        />
      ),
      filterFn: filterFunction,
      enableColumnFilter: true,
    },
  ];

  const filters: Filter[] = [
    {
      id: "status",
      name: t(`installerList.Status`),
      options: getUniqueFilterTags({
        data: installers || [],
        columnName: "status",
      }),
    },
    {
      id: "canInviteAssetOwners",
      name: t(`installerList.Can Invite`),
      // We cannot generate filter options from data here; server returns boolean result
      // Then we mapped it to InstallerCanInviteFilterStatus
      options: Object.keys(InstallerCanInviteFilterStatus),
    },
  ];

  return (
    <PageWrapper>
      <div>
        {isDistributor(userRole) && (
          <AddInstaller distributorId={distributorId} />
        )}

        <TanStackTable
          columns={columns}
          data={installers || []}
          filters={filters}
          onSelectRow={(row: Installer) =>
            onInstallerClick(row.id, row.distributorId)
          }
        />
      </div>
    </PageWrapper>
  );
};
