import { useNotification } from "hooks/useNotification";
import { useMutation, useQueryClient } from "react-query";
import api from "../axiosInstance";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import {
  CreateInstallerInvitationRequestDTO,
  CreateInstallerInvitationResponseDTO,
} from "./installerInvitation.dto";
import { SignupByCodeRequestDTO } from "../shared/signUpByCode.dto";

const ENDPOINT = "/installer-invitation";

export const useInstallerSignUp = () => {
  return useMutation(
    async (payload: SignupByCodeRequestDTO): Promise<void> =>
      (await api.post(`${ENDPOINT}/sign-up-by-code`, payload)).data,
  );
};

export const useInviteInstallerUser = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (
      payload: CreateInstallerInvitationRequestDTO,
    ): Promise<CreateInstallerInvitationResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([InstallerQueryKeys.GetOne]);
        queryClient.invalidateQueries([
          InstallerQueryKeys.GetUsers,
          data.installerId,
        ]);
        notification.info("Invitation sent");
      },
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};

export const useResendInvitation = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (oldInvitationId: number): Promise<void> =>
      (await api.post(`${ENDPOINT}/${oldInvitationId}`)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([InstallerQueryKeys.GetOne]);
        notification.info("Invitation resent, invalidated old one");
      },
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};

export const useDeleteInstallerInvitation = (settings = {}) => {
  const notification = useNotification();

  return useMutation(
    async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    {
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};
