import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FC, useState } from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { classNames } from "helpers/classNames";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  label?: string;
  name: string;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  appendix?: string;
  registerOptions?: RegisterOptions;
  size?: "third" | "half" | "full";
  errorMessage?: string;
  autoFocus?: boolean;
  placeholder?: string;
  link?: string;
  linkText?: string;
  type?: "text" | "password" | "number" | "email";
  onKeyUp?: () => void;
  autoComplete?: string;
  hoverDetails?: string;
  tabIndex?: number;
}

export const TextInput: FC<Props> = ({
  label,
  name,
  register,
  registerOptions,
  appendix,
  size = "full",
  errorMessage,
  autoFocus = false,
  placeholder,
  link,
  linkText,
  type = "text",
  onKeyUp = () => null,
  autoComplete = "nope",
  hoverDetails,
  tabIndex,
}) => {
  const hasError = !!errorMessage;
  const sizeClass = size === "third" ? "w-1/3" : size === "half" ? "w-1/2" : "";

  const [isHoveringDetails, setIsHoveringDetails] = useState(false);

  if (!registerOptions) {
    registerOptions = {};
  }
  if (type === "number") {
    registerOptions.valueAsNumber = true;
  }
  return (
    <div>
      {label && (
        <label className="flex text-sm font-medium text-gray-700 relative">
          {label}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="underline ml-4 text-flowerblue-700"
            >
              {linkText}
            </a>
          )}
          {hoverDetails && (
            <>
              <QuestionMarkCircleIcon
                onMouseEnter={() => {
                  setIsHoveringDetails(true);
                }}
                onMouseLeave={() => {
                  setIsHoveringDetails(false);
                }}
                className="h-5 w-5 ml-1 text-gray-500"
                aria-hidden="true"
              />
              {isHoveringDetails && (
                <div
                  className="text-sm bg-white border-gray-200 border-2 rounded-sm p-3 text-gray-500 absolute z-50"
                  style={{ top: 20, left: 20, width: 250 }}
                >
                  {hoverDetails}
                </div>
              )}
            </>
          )}
        </label>
      )}
      <div
        className={classNames("mt-1 relative rounded-md shadow-sm", sizeClass)}
      >
        <input
          type={type}
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm",
            hasError
              ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300"
              : "border-gray-300 focus:border-flowerblue-500 focus:ring-flowerblue-500",
            type === "email" && "lowercase",
          )}
          autoComplete={autoComplete}
          {...register(name, registerOptions && registerOptions)}
          tabIndex={tabIndex ? tabIndex : 0}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onKeyUp();
            }
          }}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {appendix && !hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm">{appendix}</span>
          </div>
        )}
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
