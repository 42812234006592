import { useMutation, useQuery } from "react-query";
import api from "../axiosInstance";
import { useTranslation } from "react-i18next";
import { useNotification } from "hooks/useNotification";
import { CollectResponseDTO, InitiateBankIDResponseDTO } from "./zavann.dto";

const ENDPOINT = "/zavann";

export enum ZavannQueryKeys {
  PollQRCode = "zavann-poll-bank-id-qr-code",
  PollBankID = "zavann-poll-bank-id",
}

export const useInitiateBankIDSignature = () => {
  const { t } = useTranslation();
  const notification = useNotification();

  return useMutation(
    async (): Promise<InitiateBankIDResponseDTO> =>
      (await api.post(`${ENDPOINT}/bankid`)).data,
    {
      onError: () => {
        notification.error(
          t(
            "Something went wrong. Check your details in previous steps and try again.",
          ),
        );
      },
    },
  );
};

export const usePollBankIDGenerateQRCode = (id: string, settings = {}) =>
  useQuery(
    [ZavannQueryKeys.PollQRCode, id],
    async (): Promise<string> =>
      (await api.get(`${ENDPOINT}/bankid-qr-code/${id}`)).data,
    settings,
  );

export const usePollBankID = (id: string, settings = {}) =>
  useQuery(
    [ZavannQueryKeys.PollBankID, id],
    async (): Promise<CollectResponseDTO> =>
      (await api.get(`${ENDPOINT}/bankid/${id}`)).data,
    settings,
  );
