import React, { FC } from "react";
import { BadgeColor, colorClass } from "./Badge";
import { classNames } from "../../helpers/classNames";

type TooltipSize = "small" | "medium" | "large";
type TooltipPosition = "top" | "bottom" | "left" | "right";

interface Props {
  children: React.ReactNode;
  message: string;
  color?: BadgeColor;
  size?: TooltipSize;
  position?: TooltipPosition;
}

export const Tooltip: FC<Props> = ({
  children,
  message,
  color = "gray",
  size = "medium",
  position = "top",
}) => (
  <>
    {children}
    <div
      className={classNames(
        "absolute z-50 ml-auto mr-auto flex flex-col items-center scale-0 rounded-lg px-3 py-2 text-xs transition-all duration-300 group-hover:scale-100",
        getTooltipPosition(position),
      )}
    >
      <div
        className={classNames(
          "relative p-2 text-xs leading-none text-wrap rounded text-center shadow",
          colorClass(color),
          getTooltipSize(size),
        )}
      >
        {message}
      </div>
    </div>
  </>
);

const getTooltipSize = (size: TooltipSize): string => {
  switch (size) {
    case "small":
      return "w-32";
    case "medium":
      return "w-44";
    case "large":
      return "w-56";
    default:
      throw Error(`getTooltipSize missing implementation for ${size}`);
  }
};

const getTooltipPosition = (position: TooltipPosition): string => {
  switch (position) {
    case "top":
      return "bottom-full left-1/2 transform -translate-x-1/2";
    case "bottom":
      return "top-full left-1/2 transform -translate-x-1/2";
    case "left":
      return "right-full top-1/2 transform -translate-y-1/2";
    case "right":
      return "left-full top-1/2 transform -translate-y-1/2";
    default:
      throw Error(`getTooltipPosition missing implementation for ${position}`);
  }
};
