import { Role } from "enums/role.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb } from "store/menu.store";
import { getDistributorListBreadcrumb } from "../pages/app/distributorList/DistributorListPage";
import { getDistributorBreadcrumb } from "../pages/app/distributor/DistributorPage";
import { getInstallerListBreadcrumb } from "../pages/app/installerList/InstallerListPage";
import { getInstallerBreadcrumb } from "./installer";

import { GetAssetOwnerResponseDTO } from "api/assetOwner/assetOwner.dto";
import { AssetOwnerTabs } from "../pages/app/assetOwnerPage/AssetOwnerPage";
import { getAssetOwnerListBreadcrumb } from "./assetOwnerList";

export const getBreadCrumbs = (
  assetOwner: GetAssetOwnerResponseDTO,
  userRole: Role,
) => {
  switch (userRole) {
    case Role.Flower:
      return [
        getDistributorListBreadcrumb(),
        getDistributorBreadcrumb({
          id: assetOwner.distributor.id ?? -1,
          name: assetOwner.distributor.name,
        }),
        getInstallerBreadcrumb(
          assetOwner.installer.name,
          assetOwner.installer.id ?? -1,
          assetOwner.distributor.id ?? -1,
          Role.Flower,
        ),
        getAssetOwnerBreadcrumb(assetOwner, Role.Flower),
      ];
    case Role.DistributorAdmin:
    case Role.DistributorUser:
      return [
        getInstallerListBreadcrumb(),
        getInstallerBreadcrumb(
          assetOwner.installer.name,
          assetOwner.installer.id ?? -1,
          assetOwner.distributor.id ?? -1,
          userRole,
        ),
        getAssetOwnerBreadcrumb(assetOwner, userRole),
      ];
    case Role.InstallerAdmin:
    case Role.InstallerUser:
      return [
        getAssetOwnerListBreadcrumb(),
        getAssetOwnerBreadcrumb(assetOwner, userRole),
      ];

    default:
      throw new Error(
        `AssetOwnerPage.getBreadCrumbs is missing implementation for role ${userRole}`,
      );
  }
};

const getAssetOwnerBreadcrumb = (
  assetOwner: GetAssetOwnerResponseDTO,
  userRole: Role,
): BreadCrumb => {
  return {
    label: `${assetOwner.firstName} ${assetOwner.lastName}`,
    to: getAssetOwnerPageRoute(
      userRole,
      assetOwner.id,
      assetOwner.installer.id,
      assetOwner.distributor.id,
    ),
    iconType: "assetOwner",
  };
};

export const getAssetOwnerPageRoute = (
  userRole: Role,
  assetOwnerId: number,
  installerId?: number,
  distributorId?: number,
) => {
  switch (userRole) {
    case Role.Flower:
      return `${RouteUrl.App}/${RouteUrl.Distributors}/${distributorId}/${installerId}/${assetOwnerId}`;
    case Role.DistributorAdmin:
    case Role.DistributorUser:
      return `${RouteUrl.App}/${RouteUrl.Installers}/${installerId}/${assetOwnerId}`;
    case Role.InstallerAdmin:
    case Role.InstallerUser:
      return `${RouteUrl.App}/${RouteUrl.AssetOwners}/${assetOwnerId}`;
    default:
      throw new Error(
        `Role ${userRole} should not access getAssetOwnerPageRoute`,
      );
  }
};

// Only available for Flower Users
export const getAssetRoute = (
  assetOwnerId: number,
  installerId?: number,
  distributorId?: number,
) =>
  `${getAssetOwnerPageRoute(
    Role.Flower,
    assetOwnerId,
    installerId,
    distributorId,
  )}?tab=${AssetOwnerTabs.Battery}`;

export function hasDifferentContactEmail(
  mainEmail: string,
  contactEmail?: string,
): boolean {
  // When the user is pending with signing, contactEmail can be null
  return !!contactEmail && mainEmail !== contactEmail;
}
