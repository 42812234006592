import { Transition } from "components/animations/Transition";
import React, { FC } from "react";
import { classNames } from "../../helpers/classNames";

export interface Tab {
  label: string;
  param: string;
  content: React.ReactNode;
  isSelected: boolean;
  labelName?: string;
  button?: React.ReactNode;
}
interface Props {
  tabs: Tab[];
  setTab: (param: string) => void;
  isAvtalskund?: boolean;
}
export const TabPageWrapper: FC<Props> = ({ tabs, setTab }) => {
  return (
    <Transition
      settings={{
        from: { opacity: 0, transform: "translate3d(0px, 30px, 0px)" },
        enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
        config: { precision: 0.02 },
      }}
    >
      <div className="md:mt-7">
        <MobileVersion tabs={tabs} setTab={setTab} />
        <DesktopVersion tabs={tabs} setTab={setTab} />

        {/* content */}
        <div className="overflow-hidden mt-4">
          {tabs.find((tab) => tab?.isSelected)?.content}
        </div>
      </div>
    </Transition>
  );
};

const MobileVersion: FC<Props> = ({ tabs, setTab }) => (
  <div className="sm:hidden">
    <label htmlFor="tabs" className="sr-only">
      Select a tab
    </label>
    <select
      id="tabs"
      name="tabs"
      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-flowerblue-500 focus:border-flowerblue-500 sm:text-sm rounded-md"
      defaultValue={tabs.find((tab: Tab) => tab?.isSelected)?.label}
      onChange={(e) => setTab(e.target.value)}
    >
      {tabs.map((tab) => (
        <option key={tab.label} value={tab.param}>
          {tab.label}
        </option>
      ))}
    </select>
    {tabs.find((tab) => tab?.isSelected)?.button && (
      <div className="mt-4 flex justify-end">
        {tabs.find((tab) => tab?.isSelected)?.button}
      </div>
    )}
  </div>
);

const DesktopVersion: FC<Props> = ({ tabs, setTab }) => (
  <div className="hidden sm:block bg-white rounded-md pr-6 border-b border-gray-200 items-center justify-between">
    <div className="flex justify-between items-center">
      <nav className="-mb-px flex space-x-8 pl-6" aria-label="Tabs">
        {tabs.map(({ label, isSelected, labelName, param }) => (
          <div
            key={label}
            onClick={() => setTab(param)}
            className={classNames(
              isSelected
                ? "border-flowerPeach-900 text-flowerPeach-900 font-semibold"
                : "border-transparent text-gray-400 hover:text-gray-600 hover:border-gray-300 font-semibold",
              "whitespace-nowrap py-4 px-1 border-b-2 text-sm cursor-pointer tracking-wide",
            )}
            aria-current={isSelected ? "page" : undefined}
          >
            {labelName ?? label}
          </div>
        ))}
      </nav>
      {tabs.find((tab) => tab?.isSelected)?.button &&
        tabs.find((tab) => tab?.isSelected)?.button}
    </div>
  </div>
);
