import { FC } from "react";
import {
  FlowerHubProgressStage,
  FlowerHubProgressContext,
} from "enums/flowerHubStatus.enum";
import { classNames } from "helpers/classNames";

import { Separator } from "./Separator";
import { StageIndicator } from "./StageIndicator";
import { StepLabel } from "./StepLabel";

interface ProgressListItemProps {
  step: ProgressStep;
  stepIdx: number;
  listLength: number;
}

export interface ProgressStep {
  step: FlowerHubProgressContext;
  stage: FlowerHubProgressStage;
}

export const ProgressListItem: FC<ProgressListItemProps> = ({
  step,
  stepIdx,
  listLength,
}) => {
  return (
    <li className="relative overflow-hidden lg:flex-1">
      <div
        className={classNames(
          stepIdx === 0 ? "rounded-t-md border-b-0" : "",
          stepIdx === listLength - 1 ? "rounded-b-md border-t-0" : "",
          "overflow-hidden border border-gray-200 lg:border-0",
        )}
      >
        <div className="group">
          <span
            className={classNames(
              step.stage === FlowerHubProgressStage.Current
                ? "bg-flowerSky-500"
                : "bg-transparent group-hover:bg-gray-200",
              "absolute left-0 top-0 h-full w-1 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full",
            )}
            aria-hidden="true"
          />
          <span
            className={classNames(
              stepIdx !== 0 ? "lg:pl-4 lg:justify-center" : "",
              "flex items-center px-6 py-2 text-sm font-medium",
            )}
          >
            <span className="flex-shrink-0">
              <StageIndicator stage={step.stage} stepIdx={stepIdx} />
            </span>
            <span className="ml-3 mt-0.5 flex min-w-0 flex-col">
              <StepLabel stage={step.stage} step={step.step} />
            </span>
          </span>
        </div>

        {stepIdx !== 0 && <Separator />}
      </div>
    </li>
  );
};
