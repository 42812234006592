import { FC } from "react";
import { Link } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { FlowerHubLogo } from "styles/Icons";

interface Props {
  header?: string;
  text?: string;
  link?: string;
  linkText?: string;
  errorCode?: string;
}
export const NotFound: FC<Props> = ({
  header = "Page not found.",
  text = "Sorry, we couldn't find the page you're looking for.",
  link = `${RouteUrl.App}`,
  linkText = "Go back home",
  errorCode = "404",
}) => {
  return (
    <div className="flex h-screen flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Flower Hub</span>
            <img className="h-16 w-auto mx-auto" src={FlowerHubLogo} />
          </a>
        </div>
        <div className="py-12">
          <div className="text-center flex flex-col items-center">
            <p className="text-base font-semibold text-flowerSky-600">
              {errorCode}
            </p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-flowerblue-700 sm:text-5xl">
              {header}
            </h1>
            <p className="mt-4 text-base text-gray-500 max-w-md">{text}</p>
            <div className="mt-6">
              <Link
                to={link}
                className={
                  "text-base font-medium text-flowerSky-600 hover:text-flowerSky-500"
                }
              >
                {linkText}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
