import { Step, StepStatus } from "components/Steps";
import { getInverterTypeName, InverterType } from "enums/inverterType.enum";
import { Dispatch, SetStateAction } from "react";
import { AssetInformation } from "components/installationFlow/steps/AssetInformationStep";

export enum AssetStep {
  CONNECT_ETH = 1,
  ASSET_INFORMATION = 2,
  SERIAL_NUMBER = 3,
  META_DATA_CONFIRMATION = 4,
  POWER_CONFIRMATION = 5,
  CONNECTING = 6,
}

export const getSteps = (
  currentStep: AssetStep,
  assetInformation: AssetInformation,
  addAssetSerialNumber: string | undefined,
  isInstalled?: boolean,
): Step[] => [
  {
    label: "Get started with installation",
    status: getConnectEthStatus(currentStep),
  },
  {
    label: "Information about the asset",
    status: getAssetStatus(assetInformation, currentStep),
  },
  {
    label: "Enter the Flower Hub serial number",
    status: getSerialNumberStatus(addAssetSerialNumber, currentStep),
  },
  {
    label: "Confirm the provided information",
    status: getMetaDataConfirmationStatus(
      assetInformation,
      addAssetSerialNumber,
      currentStep,
    ),
  },
  {
    label: "Is the Flower Hub connected to power and LAN?",
    status: getPowerConfirmationStatus(currentStep),
  },
  {
    label: "connecting",
    status: getConnectingStatus(currentStep, isInstalled),
  },
];

export const getStepAction = (
  currentStep: AssetStep,
  setCurrentStep: Dispatch<SetStateAction<AssetStep>>,
  closeModal: () => void,
  onSubmitAsset: () => void,
): (() => void) => {
  switch (currentStep) {
    case AssetStep.CONNECT_ETH:
    case AssetStep.ASSET_INFORMATION:
    case AssetStep.SERIAL_NUMBER:
    case AssetStep.META_DATA_CONFIRMATION:
      return () => setCurrentStep((prev) => prev + 1);
    case AssetStep.POWER_CONFIRMATION:
      return () => {
        onSubmitAsset();
        setCurrentStep((prev) => prev + 1);
      };
    case AssetStep.CONNECTING:
      return closeModal;
    default:
      throw Error(
        `getIsActionDisabled is missing implementation for step ${currentStep}`,
      );
  }
};

export const getAddAssetActionText = (currentStep: number) =>
  currentStep === AssetStep.CONNECT_ETH
    ? "Ok, got it!"
    : currentStep === AssetStep.META_DATA_CONFIRMATION
      ? "Yes, that's correct"
      : currentStep === AssetStep.POWER_CONFIRMATION
        ? "All cables are connected"
        : "Next";

export const isRightButtonActionDisabled = (
  currentStep: AssetStep,
  assetInformation: AssetInformation,
  addAssetSerialNumber: string | undefined,
  isConnected: boolean,
): boolean => {
  switch (currentStep) {
    case AssetStep.ASSET_INFORMATION:
      return !isAssetInformationComplete(assetInformation);
    case AssetStep.SERIAL_NUMBER:
      return !isSerialNumberComplete(addAssetSerialNumber);
    case AssetStep.META_DATA_CONFIRMATION:
      return (
        !isAssetInformationComplete(assetInformation) &&
        !isSerialNumberComplete(addAssetSerialNumber)
      );
    case AssetStep.CONNECT_ETH:
    case AssetStep.POWER_CONFIRMATION:
      return false;
    case AssetStep.CONNECTING:
      return !isConnected;
    default:
      throw Error(
        `isActionDisabled is missing implementation for step ${currentStep}`,
      );
  }
};

export const isRightButtonVisible = (
  currentStep: AssetStep,
  isConnected: boolean,
) => currentStep !== AssetStep.CONNECTING || isConnected;

const getConnectEthStatus = (currentStep: AssetStep): StepStatus =>
  currentStep === AssetStep.CONNECT_ETH
    ? StepStatus.Current
    : StepStatus.Complete;

const getAssetStatus = (
  assetInformation: AssetInformation,
  currentStep: AssetStep,
): StepStatus =>
  isAssetInformationComplete(assetInformation)
    ? StepStatus.Complete
    : currentStep === AssetStep.ASSET_INFORMATION
      ? StepStatus.Current
      : StepStatus.Upcoming;

const isAssetInformationComplete = (assetInformation: AssetInformation) => {
  return (
    assetInformation.manufacturer &&
    assetInformation.assetModel &&
    assetInformation.configuration &&
    assetInformation.inverterPowerCapacity
  );
};
const getSerialNumberStatus = (
  addAssetSerialNumber: string | undefined,
  currentStep: number,
): StepStatus =>
  isSerialNumberComplete(addAssetSerialNumber)
    ? StepStatus.Complete
    : currentStep === AssetStep.SERIAL_NUMBER
      ? StepStatus.Current
      : StepStatus.Upcoming;

const isSerialNumberComplete = (addAssetSerialNumber: string | undefined) =>
  !!addAssetSerialNumber;

const getMetaDataConfirmationStatus = (
  assetInformation: AssetInformation,
  addAssetSerialNumber: string | undefined,
  currentStep: AssetStep,
) =>
  isAssetInformationComplete(assetInformation) &&
  isSerialNumberComplete(addAssetSerialNumber) &&
  currentStep > AssetStep.META_DATA_CONFIRMATION
    ? StepStatus.Complete
    : currentStep === AssetStep.META_DATA_CONFIRMATION
      ? StepStatus.Current
      : StepStatus.Upcoming;

const getPowerConfirmationStatus = (currentStep: AssetStep): StepStatus =>
  currentStep > AssetStep.POWER_CONFIRMATION
    ? StepStatus.Complete
    : currentStep < AssetStep.POWER_CONFIRMATION
      ? StepStatus.Upcoming
      : StepStatus.Current;

const getConnectingStatus = (
  currentStep: AssetStep,
  isInstalled?: boolean,
): StepStatus =>
  isInstalled
    ? StepStatus.Complete
    : currentStep === AssetStep.CONNECTING
      ? StepStatus.Current
      : StepStatus.Upcoming;

export const INVERTER_TYPES = [
  { value: InverterType.Undefined, label: "Choose inverter type" },
  {
    value: InverterType.Standard,
    label: getInverterTypeName(InverterType.Standard),
  },
  {
    value: InverterType.Hybrid,
    label: getInverterTypeName(InverterType.Hybrid),
  },
];

// Must know currentStep and whether you are connected or not
export const getLeftButtonAction = (
  currentStep: AssetStep,
  setCurrentStep: Dispatch<SetStateAction<AssetStep>>,
): (() => void) | undefined => {
  switch (currentStep) {
    case AssetStep.CONNECT_ETH:
    case AssetStep.ASSET_INFORMATION:
      return undefined; // First step, you can't go back
    // TODO: Here we should check that all data has been filled in
    case AssetStep.SERIAL_NUMBER:
    case AssetStep.META_DATA_CONFIRMATION:
      return () => setCurrentStep((prev) => prev - 1);
    case AssetStep.POWER_CONFIRMATION:
      return undefined; // After filling out all the values you should not be able to go back
    case AssetStep.CONNECTING:
      return undefined; // Once the FH is connecting you should not be able to go back
    default:
      throw Error(
        `getSecondaryAction is missing implementation for step ${currentStep}`,
      );
  }
};

export const getLeftButtonActionText = (currentStep: AssetStep) => {
  switch (currentStep) {
    case AssetStep.CONNECT_ETH:
    case AssetStep.ASSET_INFORMATION:
    case AssetStep.POWER_CONFIRMATION:
    case AssetStep.CONNECTING:
      return undefined; // You can't go back
    case AssetStep.SERIAL_NUMBER:
    case AssetStep.META_DATA_CONFIRMATION:
      return "Back";
    default:
      throw Error(
        `getSecondaryActionText is missing implementation for step ${currentStep}`,
      );
  }
};
