import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import {
  CreateAssetModelRequestDTO,
  GetAssetModelItemResponseDTO,
  GetAssetModelResponseDTO,
} from "./assetModel.dto";

const ENDPOINT = "/asset-model";

export enum AssetModelQueryKeys {
  GetAll = "asset-model-get-all",
}

export const useAssetModels = () =>
  useQuery(
    [AssetModelQueryKeys.GetAll],
    async (): Promise<GetAssetModelItemResponseDTO[]> =>
      (await api.get(ENDPOINT)).data,
  );

export const useCreateAssetModel = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: CreateAssetModelRequestDTO,
    ): Promise<GetAssetModelResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AssetModelQueryKeys.GetAll]);
        notification.info(`Asset created`);
      },
      onError: () => {
        notification.error();
      },
      ...options,
    },
  );
};

export const useUpdateAssetModel = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (
      payload: GetAssetModelItemResponseDTO,
    ): Promise<GetAssetModelResponseDTO> =>
      (await api.put(`${ENDPOINT}/${payload.id}`, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AssetModelQueryKeys.GetAll]);
        notification.info(`Changes saved`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useDeleteAssetModel = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AssetModelQueryKeys.GetAll]);
        notification.info(`Asset model deleted`);
      },
      onError: () => {
        notification.error("Something went wrong, please try again");
      },
      ...settings,
    },
  );
};
