import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Panel } from "components/Panel";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AssetOwnerStatusTag } from "components/tags/AssetOwnerStatusTag";

import { useTranslation } from "react-i18next";
import { useLatestAssetOwnersForInstaller } from "api/assetOwner/assetOwner.endpoints";
import userStore, { getUserRole } from "store/user.store";
import { getAssetOwnerPageRoute } from "../../helpers/assetOwner";

interface Props {
  installerId?: number;
}

export const LatestAssetOwners: FC<Props> = ({ installerId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRole = userStore(getUserRole);

  const {
    data: assetOwners,
    isLoading,
    isError,
  } = useLatestAssetOwnersForInstaller(installerId);

  const onAssetOwnerClick = (assetOwnerId: number) => {
    navigate(getAssetOwnerPageRoute(userRole, assetOwnerId));
  };

  if (isLoading || isError || !assetOwners || assetOwners.length === 0)
    return null; // Mitigate placeholder views in dashboard

  return (
    <Panel header={t("dashboardStatistics.AssetOwners")}>
      <ul
        role="list"
        className="divide-y divide-gray-100 border-y border-y-gray-100"
      >
        {assetOwners.map((assetOwner) => (
          <li key={assetOwner.id}>
            <div
              onClick={() => onAssetOwnerClick(assetOwner.id)}
              className="block hover:bg-gray-50 cursor-pointer"
            >
              <div className="flex items-center py-4">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-2">
                    <div className="col-span-3">
                      <p className="truncate text-sm font-medium text-flowerSky-500">
                        {`${assetOwner.firstName} ${assetOwner.lastName}`}
                      </p>
                      <p className="flex items-center text-sm text-gray-900 mt-2">
                        <AssetOwnerStatusTag
                          status={assetOwner.accountStatus}
                        />
                      </p>
                    </div>
                    <div className="hidden md:block  col-span-2">
                      <div>
                        <p className=" text-sm text-gray-600">
                          {assetOwner.address.street}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-600">
                          <span className="truncate">
                            {`${assetOwner.address.postalCode} ${assetOwner.address.city}`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Panel>
  );
};
