import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import { FlowerSettingsDTO } from "./flowerSettings.dto";
import {
  PrequalificationGroupDTO,
  CreatePrequalificationGroupDTO,
} from "types/prequalificationGroup.type";

export const ENDPOINT = "/flower-settings";

export enum FlowerSettingsQueryKeys {
  GetSettings = "flower-settings-get-settings",
  GetPrequalificationGroup = "prequalification-groups-get",
}

export const useFlowerSettings = () =>
  useQuery(
    [FlowerSettingsQueryKeys.GetSettings],
    async (): Promise<FlowerSettingsDTO> => (await api.get(ENDPOINT)).data,
  );

export const usePrequalificationGroup = () =>
  useQuery(
    [FlowerSettingsQueryKeys.GetPrequalificationGroup],
    async (): Promise<PrequalificationGroupDTO[]> =>
      (await api.get(`${ENDPOINT}/prequalification-group`)).data,
  );

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: FlowerSettingsDTO): Promise<FlowerSettingsDTO> =>
      (await api.put(ENDPOINT, payload)).data,
    {
      onSuccess: () =>
        queryClient.invalidateQueries([FlowerSettingsQueryKeys.GetSettings]),
    },
  );
};

export const useCreatePrequalificationGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: CreatePrequalificationGroupDTO,
    ): Promise<PrequalificationGroupDTO> =>
      (await api.post(`${ENDPOINT}/prequalification-group`, payload)).data,
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          FlowerSettingsQueryKeys.GetPrequalificationGroup,
        ]),
    },
  );
};

export const useUpdatePrequalificationGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: PrequalificationGroupDTO,
    ): Promise<PrequalificationGroupDTO> =>
      (
        await api.put(
          `${ENDPOINT}/prequalification-group/${payload.id}`,
          payload,
        )
      ).data,
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          FlowerSettingsQueryKeys.GetPrequalificationGroup,
        ]),
    },
  );
};
