import { LargeLoader } from "./loaders/LargeLoader";
import { OpacityTransition } from "./animations/SnapUp";
import React from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

interface Props {
  header: string;
  children: React.ReactNode;
  button?: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  infoText?: string;
}

export const Panel = ({
  header,
  children,
  button,
  isLoading = false,
  isError = false,
  infoText,
}: Props) => (
  <div className="divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg mb-8">
    <div className="flex px-4 py-3 sm:px-6 bg-flowerSky-400">
      <h3 className="text-base font-medium leading-6 text-white">{header}</h3>
      {infoText && (
        <div className="group relative inline">
          <InformationCircleIcon
            className="inline ml-2 text-white hover:text-white-400"
            width={20}
            height={20}
          />
          <span className="sm:w-52 w-36 z-50 absolute top-5 scale-0 rounded bg-gray-600 p-2 text-sm font-normal text-white group-hover:scale-100">
            {infoText}
          </span>
        </div>
      )}
    </div>
    {isLoading ? (
      <div className="flex justify-center items-center h-48">
        <LargeLoader />
      </div>
    ) : isError ? (
      <div className="flex justify-center items-center h-48">Server error</div>
    ) : (
      <div className="px-4 py-5 sm:p-6 sm:px-3 relative">
        {!!button && <div className="absolute right-2 top-2">{button}</div>}
        <OpacityTransition>{children}</OpacityTransition>
      </div>
    )}
  </div>
);
