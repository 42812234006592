import { Badge } from "components/tags/Badge";
import { LogType } from "enums/flowerHubLog.enums";
import { classNames } from "helpers/classNames";
import { FC } from "react";

interface FlowerHubLogProps {
  type: LogType;
  log: string;
  isResolved: boolean;
  reportedAt: Date;
}

export const FlowerHubLog: FC<FlowerHubLogProps> = ({
  type,
  log,
  isResolved,
  reportedAt,
}) => {
  return (
    <li>
      <div className="relative mb-8">
        <div className="relative flex space-x-3 justify-between">
          <div>
            <span
              className={classNames(
                "h-8 w-40 rounded-full flex items-center ring-8 ring-white",
              )}
            >
              <Badge color={isResolved ? "green" : "red"} text={type} />
            </span>
          </div>
          <div className="flex-col min-w-0 justify-between pt-1.5">
            <div>
              <p className="text-sm">{log}</p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <span>{new Date(reportedAt).toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
