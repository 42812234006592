import Dropdown from "components/input/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { CreateDistributorInvitationRequestDTO } from "api/distributorInvitation/distributorInvitation.dto";

interface Props {
  register: UseFormRegister<CreateDistributorInvitationRequestDTO>;
  errors: Partial<FieldErrorsImpl<CreateDistributorInvitationRequestDTO>>;
  roles: { label: string; value: string }[];
}

export const AddUserModal: FC<Props> = ({ register, errors, roles }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="Name"
        name="name"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.name?.message}
      />
      <TextInput
        label="Email"
        name="email"
        type="email"
        register={register}
        registerOptions={emailValidator}
        errorMessage={errors?.email?.message}
      />
      <Dropdown
        items={roles}
        name="role"
        label="Account type"
        register={register}
      />
    </ModalContentWrapper>
  );
};
