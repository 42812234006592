import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import { CreateSystemNotificationDTO } from "./systemNotification.dto";
import { SystemNotification } from "./systemNotification.type";

const ENDPOINT = "/system-notification";

export enum SystemNotificationQueryKeys {
  GetAll = "system-notification",
  GetActiveZavann = "active-zavann",
  GetActiveFlower = "active-flower",
}

export const useSystemNotifications = () =>
  useQuery(
    [SystemNotificationQueryKeys.GetAll],
    async (): Promise<SystemNotification[]> => (await api.get(ENDPOINT)).data,
  );

export const useActiveZavannSystemNotifications = () =>
  useQuery(
    [SystemNotificationQueryKeys.GetActiveZavann],
    async (): Promise<SystemNotification> =>
      (
        await api.get(
          `${ENDPOINT}/${SystemNotificationQueryKeys.GetActiveZavann}`,
        )
      ).data,
  );

export const useActiveZavannSystemNotificationsByCode = (code: string) =>
  useQuery(
    [SystemNotificationQueryKeys.GetActiveZavann, code],
    async (): Promise<SystemNotification> =>
      (
        await api.get(
          `${ENDPOINT}/${SystemNotificationQueryKeys.GetActiveZavann}/${code}`,
        )
      ).data,
  );

export const useCreateSystemNotification = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateSystemNotificationDTO): Promise<SystemNotification> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SystemNotificationQueryKeys.GetAll]);
        notification.info(`System notification created`);
      },
      onError: () => {
        notification.error();
      },
      ...options,
    },
  );
};

export const useDeleteSystemNotification = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SystemNotificationQueryKeys.GetAll]);
        notification.info(`System notification deleted`);
      },
      onError: () => {
        notification.error("Something went wrong, please try again");
      },
      ...settings,
    },
  );
};

export const useActiveFlowerSystemNotifications = () =>
  useQuery(
    [SystemNotificationQueryKeys.GetActiveFlower],
    async (): Promise<SystemNotification> =>
      (
        await api.get(
          `${ENDPOINT}/${SystemNotificationQueryKeys.GetActiveFlower}`,
        )
      ).data,
  );
