import { classNames } from "helpers/classNames";
import { FC } from "react";
import { Header } from "./Header";

interface Props {
  children: React.ReactNode;
  isWide: boolean;
}
export const Content: FC<Props> = ({ children, isWide }) => (
  <main className="flex-1">
    <div className="py-6">
      <Header />
      <div
        className={classNames(
          "mr-auto px-4 sm:px-6 md:px-8 mt-6 pb-4",
          isWide ? "max-w-screen-2xl" : "max-w-screen-xl",
        )}
      >
        {children}
      </div>
    </div>
  </main>
);
