import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useRequestOTP, useVerifyOTP } from "api/auth/auth.endpoints";
import OtpInput from "components/input/OtpInput";
import { Badge } from "components/tags/Badge";
import { FC, useState, ChangeEvent, KeyboardEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface PostBillingConfirmationModalProps {
  open: boolean;
  closeModal: () => void;
  onOTPVerified: () => void;
}

const PostBillingAdjustmentConfirmationModal: FC<
  PostBillingConfirmationModalProps
> = ({ open, closeModal, onOTPVerified }) => {
  const { t } = useTranslation();
  const [inputCode, setInputCode] = useState<string[]>(new Array(6).fill(""));
  const [error, setError] = useState<string | null>(null);
  const {
    mutate: validateOtp,
    data: isValidOtp,
    isLoading: isVerifyingOtp,
  } = useVerifyOTP();
  const { mutate: requestOtp } = useRequestOTP();

  useEffect(() => {
    if (open) {
      requestOtp();
    } else {
      setInputCode(new Array(6).fill(""));
    }
  }, [open, requestOtp]);

  useEffect(() => {
    if (!isVerifyingOtp) {
      if (isValidOtp === false) {
        setError(
          t(
            "billingAdjustment.The OTP you entered is invalid, please try again",
          ),
        );
      } else if (isValidOtp === true) {
        onOTPVerified();
        setError(null); // Clear any existing error on success
      } else {
        setError(null); // No OTP validation result yet
      }
    }
  }, [isVerifyingOtp, isValidOtp, onOTPVerified]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) {
      const newCode = [...inputCode];
      newCode[index] = value;
      setInputCode(newCode);

      // Focus the next input if a value is entered
      if (value && index < 5) {
        (
          document.getElementById(`code-input-${index + 1}`) as HTMLInputElement
        )?.focus();
      }
    }
    setError(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !inputCode[index] && index > 0) {
      // Focus the previous input if backspace is pressed and current input is empty
      (
        document.getElementById(`code-input-${index - 1}`) as HTMLInputElement
      )?.focus();
    }
  };

  const isValidCode = () => {
    const code = inputCode.join("");
    return code.length === 6 && /^\d+$/.test(code);
  };

  const handleSend = async () => {
    validateOtp({ userOtp: inputCode.join("") });
  };

  return (
    <Dialog open={open} onClose={closeModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:my-8 max-w-xl sm:p-6"
          >
            <div className="text-center">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <BanknotesIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-green-600"
                />
              </div>
              <div className="mt-3 sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  {t("billingAdjustment.Send Billing Adjustments")}
                </DialogTitle>
                <p className="mt-2 text-sm text-gray-500">
                  {t(
                    "billingAdjustment.An OTP which is valid for 2 minutes, has been sent to your email. Enter that code below to proceed",
                  )}
                </p>
                <div className="cursor-pointer" onClick={() => requestOtp()}>
                  <Badge color="yellow" text={t("Resend OTP")} />
                </div>
                <OtpInput
                  value={inputCode}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  error={error}
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={() => handleSend()}
                disabled={!isValidCode()}
                className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:col-start-2 ${
                  isValidCode()
                    ? "bg-indigo-600 text-white hover:bg-indigo-500"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
              >
                {t("Send")}
              </button>
              <button
                type="button"
                data-autofocus
                onClick={closeModal}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                {t("Cancel")}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default PostBillingAdjustmentConfirmationModal;
