import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Item, Label, List, Value } from "components/descriptionList";
import CustomPlaceholder from "../CustomPlaceholder";
import { useAsset } from "../../api/asset/asset.endpoints";
import { Battery0Icon } from "@heroicons/react/24/outline";
import { EditAsset } from "./EditAsset";
import { Role } from "enums/role.enum";
import { isAssetOwner, isFlower } from "api/user/user.type";
import { FlowerHubStatusTag } from "components/tags/FlowerHubStatusTag";
import { getInverterTypeName } from "enums/inverterType.enum";

interface Props {
  userRole: Role;
  assetOwnerId?: number;
  assetId?: number;
  showEditAsset?: boolean;
}

export const Battery: FC<Props> = ({
  userRole,
  assetOwnerId,
  assetId,
  showEditAsset = false,
}) => {
  const { t } = useTranslation();

  // Get asset
  const {
    data: asset,
    isLoading: isLoadingAsset,
    isError: isErrorAsset,
  } = useAsset(assetId);

  const renderPlaceholder = () => (
    <div className="my-8">
      <CustomPlaceholder
        isError={isErrorAsset}
        isLoading={isLoadingAsset}
        isEmpty={!isLoadingAsset && !isErrorAsset && !asset}
        icon={<Battery0Icon />}
        errorMessage={t(`batteryOverview.UnableToFetchTheBattery`)}
        emptyDataMessage={t(`batteryOverview.NoBattery`)}
        loaderSize="medium"
      />
    </div>
  );

  if (isLoadingAsset || !asset || isErrorAsset) {
    return renderPlaceholder();
  }

  return (
    <>
      <List>
        {isAssetOwner(userRole) && (
          <Item>
            <Label>{t(`flowerHub.Title`)}</Label>
            <FlowerHubStatusTag
              flowerHubStatus={asset.flowerHubStatus}
              isFlowerUser={isFlower(userRole)}
            />
          </Item>
        )}
        <Item>
          <Label>{t(`batteryOverview.BESS Model`)}</Label>
          <Value>{`${asset.assetModel.manufacturer} ${asset.assetModel.name}`}</Value>
        </Item>
        <Item>
          <Label>{t(`batteryOverview.BESS power capacity`)}</Label>
          <Value appendix="kW">{asset.bessPowerCapacity}</Value>
        </Item>
        <Item>
          <Label>{t(`batteryOverview.Capacity`)}</Label>
          <Value appendix="kWh">{asset.bessCapacity}</Value>
        </Item>
        <Item>
          <Label>{t(`batteryOverview.InverterPowerCapacity`)}</Label>
          <Value appendix="kW">{asset.inverterPowerCapacity}</Value>
        </Item>
        <Item>
          <Label>{t(`batteryOverview.FuseSize`)}</Label>
          {asset.fuseSize != 0 ? (
            <Value appendix="A">{asset.fuseSize}</Value>
          ) : (
            <Value>-</Value>
          )}
        </Item>
        {isFlower(userRole) && (
          <Item>
            <Label>{t(`batteryOverview.InverterType`)}</Label>
            <Value>{getInverterTypeName(asset.inverterType)}</Value>
          </Item>
        )}
      </List>

      {showEditAsset && <EditAsset assetOwnerId={assetOwnerId} asset={asset} />}
    </>
  );
};
