import { FC } from "react";
import { Panel } from "components/Panel";
import { useTranslation } from "react-i18next";
import { useGetLatestInstallers } from "api/distributor/distributor.endpoints";
import userStore, { getUserRole } from "store/user.store";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { formatDate } from "helpers/date";
import { getRoleName } from "api/user/user.type";
import { getInstallerPageRoute } from "helpers/installer";

interface Props {
  distributorId?: number;
}
export const LatestInstallerList: FC<Props> = ({ distributorId }) => {
  const { t } = useTranslation();
  const userRole = userStore(getUserRole);

  const {
    data: installers,
    isLoading,
    isError,
  } = useGetLatestInstallers(distributorId);

  if (isLoading || isError || !installers || installers.length === 0)
    return null; // Mitigate placeholder views in dashboard

  return (
    <Panel header={t("installerList.Recent installer users")}>
      <ul role="list" className="divide-y divide-gray-100">
        {installers.map((installer) => (
          <Link
            key={installer.installerId}
            className="block hover:bg-gray-50"
            to={getInstallerPageRoute(
              userRole,
              installer.installerId,
              distributorId,
            )}
          >
            <div className="flex items-center py-4">
              <div className="flex min-w-0 flex-1 items-center">
                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-4">
                  <div className="col-span-3">
                    <p className="truncate text-sm font-medium text-flowerSky-500">
                      {installer.name}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-900">
                      <span className="truncate">{installer.organization}</span>
                    </p>
                  </div>
                  <div className="hidden md:block col-span-2">
                    <div>
                      <p className="flex items-center text-sm text-gray-900">
                        {getRoleName(installer.role)}
                      </p>
                      <p className="mt-2 text-sm text-gray-500">
                        {formatDate(new Date(installer.createdAt))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </Link>
        ))}
      </ul>
    </Panel>
  );
};
