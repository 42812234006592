import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import {
  CreateAssetRequestDTO,
  CreateAssetResponseDTO,
  GetAssetItemResponseDTO,
  GetAssetRevenueWithAssetModelResponseDTO,
  GetAssetResponseDTO,
  GetAssetStatisticsResponseDTO,
  GetCheckSerialNumberResponseDTO,
  UpdateAssetResponseDTO,
  UpdateAssetRequestDTO,
} from "./asset.dto";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";

const ENDPOINT = "/asset";

export enum AssetQueryKeys {
  GetAll = "asset-get-assets",
  GetOne = "asset-get-asset",
  GetRevenue = "asset-get-revenue",
  CheckSerialNumber = "asset-check-serial-number",
  GetAssetStatistics = "asset-get-asset-statistics",
}

export const useAssets = () =>
  useQuery(
    [AssetQueryKeys.GetAll],
    async (): Promise<GetAssetItemResponseDTO[]> =>
      (await api.get(ENDPOINT)).data,
  );

export const useAssetRevenue = (id?: number) => {
  return useQuery(
    [AssetQueryKeys.GetRevenue, id],
    async (): Promise<GetAssetRevenueWithAssetModelResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/revenue`)).data,
    {
      enabled: !!id,
    },
  );
};

export const useAsset = (id?: number) => {
  return useQuery(
    [AssetQueryKeys.GetOne, id],
    async (): Promise<GetAssetResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    {
      enabled: !!id,
    },
  );
};

export const useCheckSerialNumber = (
  isPollingEnabled: boolean,
  id?: number,
) => {
  return useQuery(
    [AssetQueryKeys.CheckSerialNumber, id],
    async (): Promise<GetCheckSerialNumberResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/check-serial-number`)).data,
    { enabled: isPollingEnabled, refetchInterval: 1000 },
  );
};

export const useAssetStatistics = (id: number) => {
  return useQuery(
    [AssetQueryKeys.GetAssetStatistics, id],
    async (): Promise<GetAssetStatisticsResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/statistics`)).data,
  );
};

export const useCreateAsset = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateAssetRequestDTO): Promise<CreateAssetResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: (data: CreateAssetResponseDTO) => {
        queryClient.invalidateQueries([
          AssetOwnerQueryKeys.GetOne,
          data.assetOwnerId,
        ]);
        queryClient.invalidateQueries([AssetOwnerQueryKeys.GetAll]);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useUpdateAsset = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (data: {
      payload: UpdateAssetRequestDTO;
      id: number;
    }): Promise<UpdateAssetResponseDTO> =>
      (await api.put(`${ENDPOINT}/${data.id}`, data.payload)).data,
    {
      onSuccess: (data: UpdateAssetResponseDTO) => {
        queryClient.invalidateQueries([AssetQueryKeys.GetOne, data.id]);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};
