import { FC, useState } from "react";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/24/outline";
import { SystemNotification } from "api/system-notification/systemNotification.type";
import { useDeleteSystemNotification } from "api/system-notification/systemNotification.endpoints";
import { ModalContentWrapper } from "components/ModalContentWrapper";

interface Props {
  systemNotification: SystemNotification;
}

export const DeleteSystemNotification: FC<Props> = ({ systemNotification }) => {
  const { t } = useTranslation();

  // state
  const [
    isDeleteSystemNotificationModalOpen,
    setIsDeleteSystemNotificationModalOpen,
  ] = useState<boolean>(false);

  // api
  const { mutate: deleteSystemNotification } = useDeleteSystemNotification();

  // submit
  const onDeleteSystemNotification = (id: number) =>
    deleteSystemNotification(id);

  // modal close
  const onDeleteSystemNotificationModalClose = () => {
    setIsDeleteSystemNotificationModalOpen(false);
  };

  const renderDeleteSystemNotificationButton = () => {
    return (
      <TrashIcon
        className="h-5 w-5 text-flowerRed-500  hover:text-flowerRed-800"
        onClick={() => setIsDeleteSystemNotificationModalOpen(true)}
      />
    );
  };

  return (
    <>
      {renderDeleteSystemNotificationButton()}

      <Modal
        isOpen={isDeleteSystemNotificationModalOpen}
        onClose={onDeleteSystemNotificationModalClose}
        title={t(`systemNotification.Delete system notification`)}
        rightButtonActionHandler={() =>
          onDeleteSystemNotification(systemNotification.id)
        }
        loadingText={t(`systemNotification.Deleting...`)}
        leftButtonAction={onDeleteSystemNotificationModalClose}
        leftButtonText={t(`close`)}
      >
        <ModalContentWrapper>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure that you want to delete {systemNotification.type}{" "}
              notification?
            </p>
          </div>
        </ModalContentWrapper>{" "}
      </Modal>
    </>
  );
};
