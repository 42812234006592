import { useMutation } from "react-query";
import {
  clearRefreshTokenExpirationDate,
  setRefreshTokenExpirationDate,
} from "store/refreshToken.store";
import userStore from "store/user.store";
import api from "../axiosInstance";
import { LoginDTO, VerifyRequestDTO } from "./auth.dto";
import { User } from "api/user/user.type";
import { useNotification } from "hooks/useNotification";

export enum AuthEndPoints {
  Login = "/auth/login",
  Logout = "/auth/logout",
  RefreshToken = "/auth/refresh-token",
  RequestOTP = "/auth/request-otp",
  VerifyOTP = "/auth/verify-otp",
}

export const useLogin = () => {
  const { setUser } = userStore();

  const {
    mutate: login,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    async (payload: LoginDTO): Promise<LoginResponse> =>
      (await api.post(AuthEndPoints.Login, payload)).data,
    {
      onSuccess: (data) => {
        setUser(data.user);
        setRefreshTokenExpirationDate(
          new Date(data.refreshTokenExpirationDate).getTime().toString(),
        );
      },
    },
  );

  return { login, isLoading, isSuccess, isError };
};

export const useServerLogout = () => {
  const { clearUser } = userStore();

  return useMutation(
    async (): Promise<void> => await api.post(AuthEndPoints.Logout),
    {
      onSuccess: () => {
        clearUser();
        clearRefreshTokenExpirationDate();
      },
    },
  );
};

export const useRequestOTP = () => {
  const notification = useNotification();

  return useMutation(
    async (): Promise<string> => {
      const response = await api.post(AuthEndPoints.RequestOTP);
      return response.data?.otpToken;
    },
    {
      onError: () => {
        notification.error("Failed to send the OTP");
      },
    },
  );
};

export const useVerifyOTP = () => {
  const notification = useNotification();

  return useMutation(
    async ({ userOtp }: VerifyRequestDTO): Promise<boolean> => {
      const response = await api.post(AuthEndPoints.VerifyOTP, {
        userOtp,
      });
      return response.data?.isValid;
    },
    {
      onError: () => {
        notification.error("Failed to verify the OTP");
      },
    },
  );
};

export type LoginResponse = {
  user: User;
  refreshTokenExpirationDate: Date;
};
