import { SmoothUp } from "components/animations/SnapUp";
import { DashboardGreetingHeader } from "components/shared/DashboardGreetingHeader";
import userStore, {
  getDistributorId,
  getInstallerId,
  getUserName,
  getUserRole,
} from "store/user.store";
import { DashboardStatistics } from "../../../components/statistics/DashboardStatistics";
import { isDistributor, isFlower, isInstaller } from "api/user/user.type";
import { LatestAssetOwnersWithAssetList } from "components/assetOwner/LatestAssetOwnersWithAssetList";
import { LatestInstallerList } from "components/installer/LatestInstallerList";
import { LatestAssetOwners } from "components/assetOwner/LatestAssetOwners";

export const DashboardPage = () => {
  const { user } = userStore();
  const userName = userStore(getUserName);
  const distributorId = userStore(getDistributorId);
  const installerId = userStore(getInstallerId);
  const userRole = userStore(getUserRole);

  return (
    <SmoothUp>
      <DashboardGreetingHeader name={userName} />
      <DashboardStatistics
        installerId={isInstaller(userRole) ? user?.installerId : undefined}
        distributorId={
          isDistributor(userRole) ? user?.distributorId : undefined
        }
      />
      {isFlower(userRole) && <LatestAssetOwnersWithAssetList />}
      {isDistributor(userRole) && (
        <LatestInstallerList distributorId={distributorId} />
      )}
      {isInstaller(userRole) && <LatestAssetOwners installerId={installerId} />}
    </SmoothUp>
  );
};
