import { useFlowerSettings } from "api/flowerSettings/flowerSettings.endpoints";
import { Item, Label, List, Value } from "components/descriptionList";
import { Shadow } from "components/Shadow";
import CustomPlaceholder from "components/CustomPlaceholder";
import { useTranslation } from "react-i18next";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { EditGeneralSettings } from "./EditGeneralSettings";

export const GeneralSettings = () => {
  const { t } = useTranslation();

  const { data: flowerSettings, isLoading, isError } = useFlowerSettings();

  if (isLoading || isError || !flowerSettings) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !flowerSettings}
          icon={<Cog6ToothIcon />}
          errorMessage={t(`flowerSettings.error`)}
          emptyDataMessage={t(`flowerSettings.empty`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <>
      <Shadow>
        <List>
          <Item>
            <Label>{t(`flowerSettings.Compensation - asset owner`)}</Label>
            <Value appendix="SEK/kW/month">
              {flowerSettings.compensationPerKwAssetOwner}
            </Value>
          </Item>
          <Item>
            <Label>{t(`flowerSettings.Compensation - installer`)}</Label>
            <Value appendix="SEK/kW/month">
              {flowerSettings.compensationPerKwInstaller}
            </Value>
          </Item>
        </List>
      </Shadow>

      <EditGeneralSettings flowerSettings={flowerSettings} />
    </>
  );
};
