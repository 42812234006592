import { CreateDistributorDTO } from "api/distributor/distributor.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<CreateDistributorDTO>;
  errors: Partial<FieldErrorsImpl<CreateDistributorDTO>>;
}
const AddDistributorForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t("distributorList.Company name")}
        name="name"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      <TextInput
        label={t("distributorList.Organization number")}
        name="organizationNumber"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput
        label={t("distributorList.emailAddressFirstUser")}
        name="firstUserEmail"
        type="email"
        register={register}
        registerOptions={emailValidator}
        errorMessage={errors?.firstUserEmail?.message}
      />
    </ModalContentWrapper>
  );
};

export default AddDistributorForm;
