import { UpdateDistributorDTO } from "api/distributor/distributor.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { PostalCodeInput } from "components/input/PostalCodeInput";
import { TextInput } from "components/input/TextInput";
import { noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";

interface Props {
  register: UseFormRegister<UpdateDistributorDTO>;
  errors: Partial<FieldErrorsImpl<UpdateDistributorDTO>>;
}

export const EditDistributorModal: FC<Props> = ({ register, errors }) => {
  return (
    <ModalContentWrapper>
      <TextInput
        label="Company name"
        name="name"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.name?.message}
        autoFocus
      />
      <TextInput
        label="Organization number"
        name="organizationNumber"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.organizationNumber?.message}
      />
      <TextInput
        label="Address"
        name="address"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.address?.message}
      />
      <PostalCodeInput
        label="Postal code"
        name="postalCode"
        register={register}
        errorMessage={errors?.postalCode?.message}
      />
      <TextInput
        label="City"
        name="city"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.city?.message}
      />
      <TextInput
        label="Bankgiro / postgiro"
        name="giroNumber"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.giroNumber?.message}
      />
    </ModalContentWrapper>
  );
};
