import { MapPinIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { FC, useMemo, useState } from "react";
import { getAssetOwnerName } from "../../helpers/assetOwnerList";
import { GetAssetOwnerItemResponseDTO } from "api/assetOwner/assetOwner.dto";
import { SearchField } from "components/input/SearchField";
import { escapeRegExp } from "helpers/string";

interface Props {
  assetOwners?: GetAssetOwnerItemResponseDTO[];
  onAssetOwnerClick: (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => void;
}

export const MobileList: FC<Props> = ({
  assetOwners = [],
  onAssetOwnerClick,
}) => {
  const [searchInput, setSearchInput] = useState("");

  const filteredData = useMemo(() => {
    if (!searchInput) {
      return assetOwners;
    }

    const searchRegex = new RegExp(escapeRegExp(searchInput), "i");

    return assetOwners.filter((item: GetAssetOwnerItemResponseDTO) => {
      const searchableProperties = [
        "firstName",
        "lastName",
        "phone",
        "address",
      ];

      return (
        searchableProperties.some((property) => {
          //[LINT_TODO] Use general search and filters in the mobile version
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const propertyValue = (item as any)[property];

          if (typeof propertyValue !== "string") {
            const searchableAddressProperties = [
              "street",
              "postalCode",
              "city",
            ];

            return searchableAddressProperties.some((addressProperty) => {
              //[LINT_TODO] Use general search and filters in the mobile version
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const addressPropertyValue = (item.address as any)[
                addressProperty
              ];

              return (
                addressPropertyValue &&
                searchRegex.test(addressPropertyValue.toLowerCase())
              );
            });
          }

          return propertyValue && searchRegex.test(propertyValue.toLowerCase());
        }) ||
        (item.firstName &&
          item.lastName &&
          searchRegex.test(`${item.firstName} ${item.lastName}`.toLowerCase()))
      );
    });
  }, [assetOwners, searchInput]);

  return (
    <div className="block overflow-hidden">
      <SearchField onSearch={(value) => setSearchInput(value)} />
      <div className="block overflow-hidden bg-white shadow rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {filteredData.map((assetOwner) => (
            <li key={assetOwner.id}>
              <div
                className="block hover:opacity-75 cursor-pointer"
                onClick={() =>
                  onAssetOwnerClick(
                    assetOwner.id,
                    assetOwner.installerId,
                    assetOwner.distributorId,
                  )
                }
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="truncate text-sm font-medium text-flowerSky-600">
                      {getAssetOwnerName(assetOwner)}
                    </p>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <PhoneIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {assetOwner.phone}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <MapPinIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {assetOwner.address.street},{" "}
                        {assetOwner.address.postalCode}{" "}
                        {assetOwner.address.city}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
