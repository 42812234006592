import { FC } from "react";
import { menuStore } from "store/menu.store";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { getBreadCrumbIcon } from "./AppWrapper.helpers";

export const Header: FC = () => {
  const { breadCrumbs } = menuStore();
  if (breadCrumbs.length === 1) return null;
  return (
    <header>
      <div className="mr-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          <div>
            {/* Breadcrumbs */}
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                {breadCrumbs.map((breadCrumb, index) => (
                  <li key={breadCrumb.to}>
                    <div className={index > 0 ? "flex items-center" : "flex"}>
                      {index > 0 && (
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      <Link
                        to={breadCrumb.to}
                        className={
                          index > 0
                            ? "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 flex items-center"
                            : "text-sm font-medium text-gray-500 hover:text-gray-700 flex items-center"
                        }
                      >
                        <>
                          {!!breadCrumb.iconType &&
                            getBreadCrumbIcon(breadCrumb.iconType)}
                          {breadCrumb.label}
                        </>
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
