import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import {
  GetAssetOwnerInvitationResponseDTO,
  ResendAssetOwnerInvitationRequestDTO,
  SignupFlowByCodeRequestDTO,
} from "./assetOwnerInvitation.dto";
import { useNotification } from "hooks/useNotification";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";

export const ENDPOINT = "/asset-owner-invitation";

export enum AssetOwnerInvitationQueryKeys {
  GetOne = "asset-owner-invitation-get-one",
}

export const useAssetOwnerSignUp = () => {
  return useMutation(async (payload: SignupFlowByCodeRequestDTO) => {
    (await api.post(`${ENDPOINT}/signup-flow-by-code`, payload)).data;
  });
};

export const useAssetOwnerInvitation = (code: string, enabled: boolean) =>
  useQuery(
    [AssetOwnerInvitationQueryKeys.GetOne],
    async (): Promise<GetAssetOwnerInvitationResponseDTO> =>
      (await api.get(`${ENDPOINT}/${code}`)).data,
    {
      enabled,
    },
  );

export const useResendInvitation = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: ResendAssetOwnerInvitationRequestDTO): Promise<void> =>
      await api.put(`${ENDPOINT}/${payload.oldInvitationId}/resend`, {
        installerId: payload.installerId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AssetOwnerQueryKeys.GetOne]);
        notification.info("Invitation resent, invalidated old one");
      },
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};
