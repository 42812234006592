import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { isEmptyArray } from "helpers/array";
import EmptyState from "components/EmptyState";
import { Shadow } from "components/Shadow";
import { Installer } from "api/installer/installer.type";

interface Props {
  installers?: Installer[];
  onInstallerClick: (id: number) => void;
}
export const Installers: FC<Props> = ({
  installers = [],
  onInstallerClick,
}) => {
  return isEmptyArray(installers) ? (
    <EmptyState
      header="No Installers"
      subHeader="No Installers have been added yet"
    />
  ) : (
    <Shadow>
      <Table>
        <THead>
          <TR>
            <TH isFirstColumn>Company</TH>
            <TH>Organization number</TH>
            <TH>City</TH>
            <TH>Contact person</TH>
          </TR>
        </THead>
        <TBody>
          {installers.map(
            ({ name, organizationNumber, city, id }: Installer) => (
              <TR key={id} onClick={() => onInstallerClick(id)}>
                <TD addLeftMargin>{name}</TD>
                <TD>{organizationNumber}</TD>
                <TD>{city}</TD>
                <TD>Placeholder</TD>
              </TR>
            ),
          )}
        </TBody>
      </Table>
    </Shadow>
  );
};
