import { FC } from "react";
import { Step, Steps } from "components/Steps";
import { ConnectedEthStep } from "components/installationFlow/steps/ConnectedEthStep";

import { SerialNumberStep } from "components/installationFlow/steps/SerialNumberStep";
import { MetaDataConfirmationStep } from "components/installationFlow/steps/MetaDataConfirmationStep";
import { PowerConfirmationStep } from "components/installationFlow/steps/PowerConfirmationStep";
import { ConnectingStep } from "components/installationFlow/steps/ConnectingStep";
import {
  AssetInformation,
  AssetInformationStep,
} from "components/installationFlow/steps/AssetInformationStep";
import { Fuse } from "components/installationFlow/bessTypes";
import { AssetStep } from "./installFlowerHub.helpers";

interface Props {
  currentStep: number;
  steps: Step[];
  isConnected: boolean;
  assetInformation: AssetInformation;
  setAssetInformation: (assetInformation: AssetInformation) => void;
  assetSerialNumber?: string;
  setAddAssetSerialNumber: (addAssetSerialNumber: string | undefined) => void;
  fuse?: Fuse;
  setFuse: (fuse: Fuse) => void;
}
export const InstallFlowerHubSteps: FC<Props> = ({
  currentStep,
  steps,
  isConnected,
  assetInformation,
  setAssetInformation,
  assetSerialNumber: assetSerialNumber,
  setAddAssetSerialNumber,
  fuse,
  setFuse,
}) => {
  return (
    <div className="mt-8 mb-4">
      <Steps steps={steps} smallPadding={true} />
      <div className="pt-8">
        {currentStep === AssetStep.CONNECT_ETH ? (
          <ConnectedEthStep />
        ) : currentStep === AssetStep.ASSET_INFORMATION ? (
          <AssetInformationStep
            assetInformation={assetInformation}
            setAssetInformation={setAssetInformation}
            fuse={fuse}
            setFuse={setFuse}
          />
        ) : currentStep === AssetStep.SERIAL_NUMBER ? (
          <SerialNumberStep
            serialNumber={assetSerialNumber}
            setSerialNumber={setAddAssetSerialNumber}
          />
        ) : currentStep === AssetStep.META_DATA_CONFIRMATION ? (
          <MetaDataConfirmationStep
            assetInformation={assetInformation}
            assetSerialNumber={assetSerialNumber}
            fuse={fuse}
          />
        ) : currentStep === AssetStep.POWER_CONFIRMATION ? (
          <PowerConfirmationStep />
        ) : currentStep === AssetStep.CONNECTING ? (
          <ConnectingStep isConnected={isConnected} />
        ) : null}
      </div>
    </div>
  );
};
