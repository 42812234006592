import { FlowerSettingsDTO } from "api/flowerSettings/flowerSettings.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { positiveNumberValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<FlowerSettingsDTO>;
  errors: Partial<FieldErrorsImpl<FlowerSettingsDTO>>;
}
export const EditGeneralSettingsForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`flowerSettings.Compensation - asset owner`)}
        name="compensationPerKwAssetOwner"
        register={register}
        registerOptions={positiveNumberValidator}
        errorMessage={errors.compensationPerKwAssetOwner?.message}
        type="number"
        appendix="SEK/kW/month"
        size="half"
      />
      <TextInput
        label={t(`flowerSettings.Compensation - installer`)}
        name="compensationPerKwInstaller"
        register={register}
        registerOptions={positiveNumberValidator}
        errorMessage={errors.compensationPerKwInstaller?.message}
        type="number"
        appendix="SEK/kW/month"
        size="half"
      />
    </ModalContentWrapper>
  );
};
