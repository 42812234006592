import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  appendix?: string;
}
export const Value: FC<Props> = ({ children, appendix }) => (
  <dd className="mt-1 text-base text-gray-900 sm:col-span-2 sm:mt-0">
    {children}
    {!!appendix && ` ${appendix}`}
  </dd>
);
