import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AssetQueryKeys,
  useUpdateAsset,
} from "../../api/asset/asset.endpoints";
import Modal from "../Modal";
import SecondaryButton from "../buttons/SecondaryButton";
import { useForm } from "react-hook-form";
import {
  GetAssetResponseDTO,
  UpdateAssetRequestDTO,
} from "api/asset/asset.dto";
import { useQueryClient } from "react-query";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";
import { EditAssetForm } from "./EditAssetForm";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

interface Props {
  assetOwnerId?: number;
  asset: GetAssetResponseDTO;
}

export const EditAsset: FC<Props> = ({ assetOwnerId, asset }) => {
  const { t } = useTranslation();

  // id of the asset you want to edit, populate the form with
  const [assetIdToUpdate, setAssetIdToUpdate] = useState<number | null>(null);

  // api
  const {
    mutate: updateAsset,
    isLoading: isUpdatingAsset,
    isSuccess: isUpdateAssetSuccess,
  } = useUpdateAsset();

  // form
  const {
    register: registerUpdateAsset,
    handleSubmit: handleUpdateAssetSubmit,
    reset: resetUpdateAsset,
    formState: { errors: updateAssetErrors },
  } = useForm<UpdateAssetRequestDTO>();

  // submit
  const onUpdateAssetSubmit = handleUpdateAssetSubmit(
    (payload: UpdateAssetRequestDTO) => {
      if (!assetIdToUpdate || !asset) {
        throw Error("Missing asset in onUpdateAssetSubmit");
      }

      updateAsset({
        payload: {
          ...payload,
          assetModelId: +payload.assetModelId,
          inverterType: payload.inverterType && +payload.inverterType,
        },
        id: assetIdToUpdate,
      });
    },
  );

  // modal close
  const onUpdateAssetModalClose = () => {
    setAssetIdToUpdate(null);
    setTimeout(() => resetUpdateAsset(), 300);
  };

  // after submit success
  const queryClient = useQueryClient();
  useEffect(() => {
    if (isUpdateAssetSuccess) {
      onUpdateAssetModalClose();

      queryClient.invalidateQueries([AssetOwnerQueryKeys.GetAll, assetOwnerId]);
      queryClient.invalidateQueries([AssetQueryKeys.GetOne, assetIdToUpdate]);
      queryClient.invalidateQueries([AssetQueryKeys.GetAll, assetIdToUpdate]);
    }
  }, [isUpdateAssetSuccess]);

  // Populate or clear the form every time assetIdToUpdate is set
  useEffect(() => {
    if (assetIdToUpdate) {
      resetUpdateAsset({
        ...asset,
        assetModelId: asset.assetModel.id,
      });
    } else {
      setTimeout(() => resetUpdateAsset(), 300);
    }
  }, [assetIdToUpdate]);

  const renderEditAssetButton = (assetId: number) => {
    return (
      <div className="py-5 pr-4 flex justify-end">
        <SecondaryButton onClick={() => setAssetIdToUpdate(assetId)}>
          <PencilSquareIcon className="h-5 w-5 mr-1" />{" "}
          {t(`batteryOverview.EditBattery`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderEditAssetButton(asset.id)}

      <Modal
        title={t(`batteryOverview.EditBattery`)}
        rightButtonText={t(`SaveButton`)}
        rightButtonActionHandler={onUpdateAssetSubmit}
        isOpen={!!assetIdToUpdate}
        onClose={onUpdateAssetModalClose}
        isLoading={isUpdatingAsset}
      >
        <EditAssetForm
          register={registerUpdateAsset}
          errors={updateAssetErrors}
        />
      </Modal>
    </>
  );
};
