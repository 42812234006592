import { PencilSquareIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import Modal from "components/Modal";
import { PageLoader } from "components/loaders/PageLoader";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { capitalizeFirstLetter } from "helpers/string";
import { useCallback, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { BreadCrumb, menuStore } from "store/menu.store";
import { UsersTab } from "../UsersTab";
import {
  DistributorQueryKeys,
  useDistributor,
  useDistributorUsers,
  useUpdateDistributor,
} from "api/distributor/distributor.endpoints";
import userStore, { getDistributorId } from "store/user.store";
import { AddUserModal } from "pages/app/distributor/AddUserModal";

import {
  useDeleteDistributorInvitation,
  useInviteDistributorUser,
} from "api/distributorInvitation/distributorInvitation.endpoints";
import { useQueryClient } from "react-query";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { useDeactivateUser } from "api/user/user.endpoints";
import { AccountDetails } from "pages/app/distributor/AccountDetails";
import SecondaryButton from "components/buttons/SecondaryButton";
import { EditDistributorModal } from "pages/app/distributor/EditDistributorModal";
import { Role } from "enums/role.enum";
import { UpdateDistributorDTO } from "api/distributor/distributor.dto";
import {
  CreateDistributorInvitationRequestDTO,
  CreateDistributorInvitationResponseDTO,
} from "api/distributorInvitation/distributorInvitation.dto";
import { useNotification } from "../../../../hooks/useNotification";

const GENERAL_TAB = "general";
const USERS_TAB = "users";

export const DistributorOrganizationPage = () => {
  /**
   * Breadcrumbs
   */
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs([getOrganizationBreadcrumb()]);
  }, []);

  /**
   * Get distributor
   */
  const distributorId = userStore(getDistributorId);
  const { data: distributor, isLoading: isLoadingDistributor } = useDistributor(
    distributorId ?? -1,
  );

  // Users
  const {
    data: useDistributorUsersResponse,
    isLoading: isLoadingDistributorUsers,
  } = useDistributorUsers(distributorId ?? -1);

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || GENERAL_TAB;

  /**
   * Invite user
   */
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const notification = useNotification();
  const {
    mutate: inviteUser,
    isLoading: isInvitingUser,
    isSuccess: isInviteUserSuccess,
  } = useInviteDistributorUser({
    onSuccess: (result: CreateDistributorInvitationResponseDTO) => {
      queryClient
        .invalidateQueries([
          DistributorQueryKeys.GetUsers,
          result.distributorId,
        ])
        .then(() => notification.info(`${result.email} invited`));
    },
  });

  const {
    register: registerInvitation,
    handleSubmit: handleInvitationSubmit,
    reset: resetInvitation,
    formState: { errors: invitationErrors },
    setValue,
  } = useForm<CreateDistributorInvitationRequestDTO>({
    defaultValues: { distributorId },
  });

  useEffect(() => {
    setValue("distributorId", distributorId ?? -1);
  }, [distributorId]);

  const onInvitationSubmit = handleInvitationSubmit(
    (data: CreateDistributorInvitationRequestDTO) =>
      inviteUser({ ...data, role: Number(data.role) }),
  );

  const onInvitationModalClose = useCallback(() => {
    setIsInviteUserModalOpen(false);
    setTimeout(() => resetInvitation(), 300); // Wait for modal to close
  }, [resetInvitation]);

  useEffect(() => {
    if (isInviteUserSuccess) {
      onInvitationModalClose();
    }
  }, [isInviteUserSuccess, onInvitationModalClose]);

  /**
   * Deactivate user
   */
  const { mutate: deactivateUser } = useDeactivateUser({
    onSuccess: () =>
      queryClient.invalidateQueries([
        DistributorQueryKeys.GetUsers,
        distributorId,
      ]),
  });
  const onDeactivateUser = (id: number) => deactivateUser(id);

  /**
   * Delete invitation
   */
  const { mutate: deleteInvitation } = useDeleteDistributorInvitation({
    onSuccess: () =>
      queryClient
        .invalidateQueries([DistributorQueryKeys.GetUsers, distributorId])
        .then(() => notification.info("Invitation deleted")),
  });
  const onDeleteInvitation = (id: number) => deleteInvitation(id);

  /**
   * Update distributor
   */
  const [isEditDistributorModalOpen, setIsEditDistributorModalOpen] =
    useState<boolean>(false);

  const {
    mutate: updateDistributor,
    isLoading: isUpdatingDistributor,
    isSuccess: isUpdateDistributorSuccess,
  } = useUpdateDistributor(+(distributorId ?? -1));

  const {
    register,
    handleSubmit,
    reset: resetUpdateDistributor,
    formState: { errors },
  } = useForm<UpdateDistributorDTO>();

  const onSubmit = handleSubmit((data: UpdateDistributorDTO) =>
    updateDistributor(data),
  );

  useEffect(() => {
    if (distributor) {
      resetUpdateDistributor({ ...distributor });
    }
  }, [distributor]);

  useEffect(() => {
    if (isUpdateDistributorSuccess) {
      onEditDistributorModalClose();
    }
  }, [isUpdateDistributorSuccess]);

  const onEditDistributorModalClose = () => {
    setIsEditDistributorModalOpen(false);
  };

  if (isLoadingDistributorUsers || isLoadingDistributor) {
    return <PageLoader />;
  }

  if (!useDistributorUsersResponse || !distributor) {
    return <h1>Error</h1>;
  }

  const { users, invitations } = useDistributorUsersResponse;

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            param: GENERAL_TAB,
            label: capitalizeFirstLetter(GENERAL_TAB),
            content: <AccountDetails distributor={distributor} />,
            isSelected: currentTab === GENERAL_TAB,
            button: (
              <SecondaryButton
                size="small"
                onClick={() => setIsEditDistributorModalOpen(true)}
              >
                <PencilSquareIcon className="h-5 w-5 mr-1" /> Edit
              </SecondaryButton>
            ),
          },
          {
            param: USERS_TAB,
            label: capitalizeFirstLetter(USERS_TAB),
            content: (
              <UsersTab
                users={users}
                invitations={invitations}
                deactivateUser={onDeactivateUser}
                deleteInvitation={onDeleteInvitation}
              />
            ),
            isSelected: currentTab === USERS_TAB,
            button: (
              <SecondaryButton
                onClick={() => setIsInviteUserModalOpen(true)}
                size="small"
              >
                <UserPlusIcon className="h-5 w-5 mr-1" /> Add user
              </SecondaryButton>
            ),
          },
        ]}
        setTab={setTab}
      />
      <Modal
        isOpen={isInviteUserModalOpen}
        onClose={onInvitationModalClose}
        title="Add user"
        rightButtonText="Send invitation"
        rightButtonActionHandler={onInvitationSubmit}
        isLoading={isInvitingUser}
        loadingText="Sending invitation..."
        leftButtonAction={onInvitationModalClose}
        leftButtonText="Close"
      >
        <AddUserModal
          register={
            registerInvitation as UseFormRegister<CreateDistributorInvitationRequestDTO>
          }
          errors={invitationErrors}
          roles={[
            { label: "User", value: Role.DistributorUser.toString() },
            { label: "Admin", value: Role.DistributorAdmin.toString() },
          ]}
        />
      </Modal>
      <Modal
        isOpen={isEditDistributorModalOpen}
        rightButtonActionHandler={onSubmit}
        title={`Edit ${distributor.name}`}
        rightButtonText="Save"
        onClose={onEditDistributorModalClose}
        isLoading={isUpdatingDistributor}
        leftButtonAction={onEditDistributorModalClose}
        leftButtonText="Close"
      >
        <EditDistributorModal register={register} errors={errors} />
      </Modal>
    </>
  );
};

export const getOrganizationBreadcrumb = (): BreadCrumb => ({
  label: RouteLabel.Organization,
  to: `${RouteUrl.App}/${RouteUrl.Organization}`,
});
