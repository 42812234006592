import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { capitalizeFirstLetter } from "helpers/string";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, {
  getInstallerId,
  getUserId,
  getUserRole,
} from "store/user.store";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { useInstaller } from "api/installer/installer.endpoints";

import { InstallerUsersTab } from "components/installer/InstallerUsersTab";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { InstallerAccountDetails } from "components/installer/InstallerAccountDetails";

const GENERAL_TAB = "general";
const USERS_TAB = "users";

export const InstallerOrganizationPage = () => {
  const { t } = useTranslation();

  const userRole = userStore(getUserRole);
  const loggedInUserId = userStore(getUserId) || -1;
  const installerId = userStore(getInstallerId) || -1;

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || GENERAL_TAB;

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs([getOrganizationBreadcrumb()]);
  }, []);

  // Get installer
  const { data: installer, isLoading, isError } = useInstaller(installerId);

  if (isLoading || isError || !installer) {
    return (
      <CustomPlaceholder
        isEmpty={!isLoading && !isError && !installer}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`installer.Error`)}
        emptyDataMessage={t(`installer.Empty`)}
        loaderSize="page"
      />
    );
  }

  return (
    <TabPageWrapper
      tabs={[
        {
          param: GENERAL_TAB,
          label: capitalizeFirstLetter(GENERAL_TAB),
          content: (
            <InstallerAccountDetails
              installer={installer}
              userRole={userRole}
            />
          ),
          isSelected: currentTab === GENERAL_TAB,
        },
        {
          param: USERS_TAB,
          label: capitalizeFirstLetter(USERS_TAB),
          content: (
            <InstallerUsersTab
              installerId={installer.id}
              userRole={userRole}
              userId={loggedInUserId}
            />
          ),
          isSelected: currentTab === USERS_TAB,
        },
      ]}
      setTab={setTab}
    />
  );
};

export const getOrganizationBreadcrumb = (): BreadCrumb => ({
  label: RouteLabel.Organization,
  to: `${RouteUrl.App}/${RouteUrl.Organization}`,
});
