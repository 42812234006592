/* This example requires Tailwind CSS v2.0+ */

import { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "helpers/classNames";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  rightButtonText?: string;
  rightButtonActionHandler?: () => void;
  isRightButtonHidden?: boolean;
  title: string;
  rightButtonDisabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  leftButtonAction?: () => void;
  leftButtonText?: string;
  // TODO: should we add a disabled state for the left button?
}
const Modal: FC<Props> = ({
  isOpen,
  onClose,
  children,
  rightButtonText,
  rightButtonActionHandler,
  isRightButtonHidden,
  rightButtonDisabled = false,
  title,
  isLoading = false,
  loadingText = "Saving...",
  leftButtonAction,
  leftButtonText = "Close",
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg pt-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full p-2 pb-4 sm:pb-2 sm:max-w-lg">
                <div className="absolute top-0 right-0 block pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-flowerSky-500 focus:ring-offset-2"
                    onClick={onClose}
                    tabIndex={-1}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title
                      as="h3"
                      className={classNames(
                        "text-lg leading-6 font-medium text-gray-900 p-2 sm:p-4",
                      )}
                    >
                      {title}
                    </Dialog.Title>

                    <div className="w-full border-t border-gray-200" />

                    <div className="sm:px-4 sm:pb-8">{children}</div>
                  </div>
                </div>
                <div className="w-full border-t border-gray-200 mt-4 sm:mt-0" />

                <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense sm:p-4 bg-white mt-8 sm:mt-0">
                  {!isRightButtonHidden && !!rightButtonActionHandler && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-flowerPeach-900 disabled:bg-gray-400 text-base font-medium text-white hover:text-flowerPeach-900 hover:border-flowerPeach-900 hover:bg-flowerPeach-500 sm:col-start-2 sm:text-sm"
                      onClick={rightButtonActionHandler}
                      disabled={rightButtonDisabled || isLoading}
                    >
                      {isLoading && (
                        <svg
                          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}

                      {isLoading
                        ? loadingText
                          ? loadingText
                          : rightButtonText || title
                        : rightButtonText || title}
                    </button>
                  )}
                  {leftButtonAction && (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 hover:bg-white text-base font-medium text-gray-700 bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={leftButtonAction}
                    >
                      {leftButtonText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
