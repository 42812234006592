import { BadgeColor } from "components/tags/Badge";
import {
  FlowerHubProgressStage,
  FlowerHubProgressContext,
  FlowerHubStatusMessageCode,
  FlowerHubStatus,
} from "enums/flowerHubStatus.enum";
import { ProgressStep } from "components/flowerHub/flowerHubProgress/progressListItem/ProgressListItem";
import { GetFlowerHubProgressResponseDTO } from "api/flowerHub/flowerHub.dto";

export function generateProgressList(
  currentStep: FlowerHubProgressContext,
  message: FlowerHubStatusMessageCode,
): ProgressStep[] {
  const steps = [
    FlowerHubProgressContext.Register,
    FlowerHubProgressContext.Initialize,
    FlowerHubProgressContext.Install,
    FlowerHubProgressContext.Deploy,
    FlowerHubProgressContext.Connect,
    FlowerHubProgressContext.Communicate,
  ];

  // for each step except connected:
  return steps.map((step, stepIndex) => {
    let stage: FlowerHubProgressStage;
    const currentStepIndex = steps.findIndex((s) => s === currentStep);

    if (currentStepIndex > stepIndex) {
      // if step before current_step, add stage as completed
      stage = FlowerHubProgressStage.Completed;
    } else if (currentStep === step) {
      // if step is current_step, add stage as current
      stage = FlowerHubProgressStage.Current;
    } else {
      // if step is upcoming, add stage as upcoming
      stage =
        currentStep === FlowerHubProgressContext.Communicate &&
        message ===
          FlowerHubStatusMessageCode.ComponentsAreRunningAndCommunicating
          ? FlowerHubProgressStage.Completed
          : FlowerHubProgressStage.Upcoming;
    }

    return {
      step,
      stage,
    };
  });
}

export function getStatusColor(status: FlowerHubStatus) {
  const progressMap: Record<FlowerHubStatus, BadgeColor> = {
    [FlowerHubStatus.Communicating]: "green",
    [FlowerHubStatus.CommunicationError]: "red",
    [FlowerHubStatus.DeviceError]: "red",
    [FlowerHubStatus.DeploymentError]: "red",
    [FlowerHubStatus.DeploymentInProgress]: "blue",
    [FlowerHubStatus.InstallationFailed]: "red",
    [FlowerHubStatus.Registering]: "gray",
  };

  return progressMap[status];
}

const progressStepColors: Record<
  FlowerHubProgressContext,
  BadgeColor | ((progress: GetFlowerHubProgressResponseDTO) => BadgeColor)
> = {
  [FlowerHubProgressContext.Communicate]: (
    progress: GetFlowerHubProgressResponseDTO,
  ) =>
    progress.message ===
    FlowerHubStatusMessageCode.ComponentsAreRunningAndCommunicating
      ? "green"
      : "red",
  [FlowerHubProgressContext.Connect]: "red",
  [FlowerHubProgressContext.Deploy]: (
    progress: GetFlowerHubProgressResponseDTO,
  ) =>
    progress.message ===
    FlowerHubStatusMessageCode.GreengrassDeploymentInProgress
      ? "blue"
      : "red",
  [FlowerHubProgressContext.Install]: "red",
  [FlowerHubProgressContext.Initialize]: "red",
  [FlowerHubProgressContext.Register]: "gray",
};

export function getProgressStepColor(
  progress: GetFlowerHubProgressResponseDTO,
): BadgeColor {
  const colorOrFunction = progressStepColors[progress.step];

  // Check if colorOrFunction is a function or a direct color
  return typeof colorOrFunction === "function"
    ? colorOrFunction(progress)
    : colorOrFunction;
}
