import { UserPlusIcon } from "@heroicons/react/24/outline";
import {
  useDeleteFlowerInvitation,
  useFlowerInvitations,
  useCreateFlowerInvitation,
} from "api/flowerInvitation/flowerInvitation.endpoints";
import {
  useDeactivateUser,
  useFlowerUsers,
  UserQueryKeys,
} from "api/user/user.endpoints";
import { CreateFlowerInvitationRequestDTO } from "../../../../api/flowerInvitation/flowerInvitation.dto";
import Modal from "components/Modal";
import { PageLoader } from "components/loaders/PageLoader";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { capitalizeFirstLetter } from "helpers/string";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { RouteLabel } from "routing/routeLabel.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import { isEmptyArray } from "helpers/array";
import EmptyState from "components/EmptyState";
import { AddFlowerUser } from "./AddFlowerUser";
import { UsersTab } from "../UsersTab";
import SecondaryButton from "../../../../components/buttons/SecondaryButton";

const USERS_TAB = "users";

export const FlowerOrganizationPage = () => {
  // breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadcrumbs());
  }, []);

  // Users
  const { data: users, isLoading: isLoadingUsers } = useFlowerUsers();
  const { data: invitations, isLoading: isLoadingInvitations } =
    useFlowerInvitations();
  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || USERS_TAB;

  // Invite user
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const {
    mutate: inviteUser,
    isLoading: isInvitingUser,
    isSuccess: isInviteUserSuccess,
  } = useCreateFlowerInvitation();

  const {
    register: registerInvitation,
    handleSubmit: handleInvitationSubmit,
    reset: resetInvitation,
    formState: { errors: invitationErrors },
  } = useForm<CreateFlowerInvitationRequestDTO>();

  const onInvitationSubmit = handleInvitationSubmit(
    (data: CreateFlowerInvitationRequestDTO) => inviteUser(data),
  );

  const onInvitationModalClose = useCallback(() => {
    setIsInviteUserModalOpen(false);
    setTimeout(() => resetInvitation(), 300); // Wait for modal to close
  }, [resetInvitation]);

  useEffect(() => {
    if (isInviteUserSuccess) {
      onInvitationModalClose();
    }
  }, [isInviteUserSuccess, onInvitationModalClose]);

  /**
   * Delete invitation
   */
  const { mutate: deleteInvitation } = useDeleteFlowerInvitation();
  const onDeleteInvitation = (id: number) => deleteInvitation(id);

  /**
   * Deactivate user
   */
  const queryClient = useQueryClient();
  const { mutate: deactivateUser } = useDeactivateUser({
    onSuccess: () =>
      queryClient.invalidateQueries([UserQueryKeys.GetFlowerUsers]),
  });

  const onDeactivateUser = (id: number) => deactivateUser(id);

  if (isLoadingUsers || isLoadingInvitations) {
    return <PageLoader />;
  }

  const tableIsEmpty = isEmptyArray(users) && isEmptyArray(invitations);

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            param: USERS_TAB,
            label: capitalizeFirstLetter(USERS_TAB),
            content: tableIsEmpty ? (
              <EmptyState
                header="No Flower users"
                subHeader="No Flower user have been added yet"
                buttonText="Add new user"
                onClick={() => setIsInviteUserModalOpen(true)}
              />
            ) : (
              <UsersTab
                users={users}
                invitations={invitations}
                deactivateUser={onDeactivateUser}
                deleteInvitation={onDeleteInvitation}
                isFlower
              />
            ),
            isSelected: currentTab === USERS_TAB,
            button: !tableIsEmpty && (
              <SecondaryButton
                onClick={() => setIsInviteUserModalOpen(true)}
                size="small"
              >
                <UserPlusIcon className="h-5 w-5 mr-1" /> Add user
              </SecondaryButton>
            ),
          },
        ]}
        setTab={setTab}
      />
      <Modal
        isOpen={isInviteUserModalOpen}
        onClose={onInvitationModalClose}
        title="Invite user"
        rightButtonText="Send invitation"
        rightButtonActionHandler={onInvitationSubmit}
        isLoading={isInvitingUser}
        loadingText="Sending invitation..."
      >
        <AddFlowerUser
          register={registerInvitation}
          errors={invitationErrors}
        />
      </Modal>
    </>
  );
};

const getBreadcrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Organization,
    to: `${RouteUrl.App}/${RouteUrl.Organization}`,
  },
];
