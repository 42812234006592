import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import userStore, { getUserRole } from "store/user.store";
import { useServerLogout } from "api/auth/auth.endpoints";
import { useLogout } from "hooks/useLogout";
import { MobileMenu } from "./MobileMenu";
import { navigation, NavigationItem } from "./AppWrapper.helpers";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenuButton } from "./MobileMenuButton";
import { Content } from "./Content";

type Props = {
  children?: React.ReactNode;
};

export const AppWrapper: FC<Props> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuCollapsed, setSidebarCollapsed] = useState(false);
  const authLevel = userStore(getUserRole);
  const { pathname } = useLocation();
  const filteredNavigation: NavigationItem[] = navigation
    .filter((item) => item.allowedRoles.includes(authLevel))
    .map((item: NavigationItem) => ({
      ...item,
      isCurrentRoute: pathname.includes(item.href),
    }));

  // useServerLogout kills the auth-cookie.
  // useLogout clears the user-data in the client so that the app rerenders and routes to the login page
  const { mutate: logout, isSuccess } = useServerLogout();
  const handleLogout = () => logout();
  const afterLogout = useLogout();

  useEffect(() => {
    if (isSuccess) {
      afterLogout();
    }
  }, [isSuccess, afterLogout]);

  useEffect(() => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    }
  }, [pathname]);

  const isWide = !!filteredNavigation.find((item) => item.isCurrentRoute)
    ?.isWide;

  return (
    <>
      <MobileMenu
        navigation={filteredNavigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        logout={handleLogout}
      />
      <DesktopMenu
        navigation={filteredNavigation}
        logout={handleLogout}
        menuCollapsed={menuCollapsed}
        onMenuCollapse={() => setSidebarCollapsed(!menuCollapsed)}
      />

      <div
        className={`flex flex-1 flex-col h-lvh overflow-y-auto ${menuCollapsed ? "lg:pl-14" : "lg:pl-56"}`}
      >
        <MobileMenuButton openSidebar={() => setSidebarOpen(true)} />
        <Content isWide={isWide}>{children}</Content>
      </div>
    </>
  );
};
