import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../axiosInstance";
import {
  CreateInstallerDTO,
  UpdateInstallerCanInviteAssetOwnersDTO,
  UpdateInstallerDTO,
} from "./installer.dto";
import { Installer } from "./installer.type";
import { InstallerInvitation } from "api/installerInvitation/installerInvitation.type";
import { User } from "api/user/user.type";

const ENDPOINT = "/installer";

export enum InstallerQueryKeys {
  GetOne = "installer-get-one",
  GetUsers = "installer-get-installer-users",
  GetAll = "installer-get-all",
}

export const useInstallers = () =>
  useQuery(
    [InstallerQueryKeys.GetAll],
    async (): Promise<Installer[]> => (await api.get(ENDPOINT)).data,
  );

// TODO : can get it without ao list, users and invitations
// TODO : users and invitations are in InstallerUsersResponse for useInstallerUsers
export const useInstaller = (id?: number) =>
  useQuery(
    [InstallerQueryKeys.GetOne, id],
    async (): Promise<Installer> => (await api.get(`${ENDPOINT}/${id}`)).data,
    {
      enabled: !!id,
    },
  );

export const useCreateInstaller = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateInstallerDTO): Promise<Installer> =>
      (await api.post(ENDPOINT, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([InstallerQueryKeys.GetAll]);
        notification.info(`${data.name} created`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useUpdateInstaller = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: UpdateInstallerDTO): Promise<Installer> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([InstallerQueryKeys.GetOne, data.id]);
        notification.info(`${data.name} updated`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useUpdateInstallerCanInviteAssetOwners = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (
      payload: UpdateInstallerCanInviteAssetOwnersDTO,
    ): Promise<Installer> =>
      (await api.put(`${ENDPOINT}/${id}/canInviteAssetOwners`, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([InstallerQueryKeys.GetOne, data.id]);
        queryClient.invalidateQueries([InstallerQueryKeys.GetAll]);
        notification.info(`${data.name} updated`);
      },
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useInstallerUsers = (id: number) =>
  useQuery(
    [InstallerQueryKeys.GetUsers, id],
    async (): Promise<InstallerUsersResponse> =>
      (await api.get(`${ENDPOINT}/${id}/user`)).data,
    { initialData: { users: [], invitations: [] } },
  );

type InstallerUsersResponse = {
  users: User[];
  invitations: InstallerInvitation[];
};
