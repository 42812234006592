import { FC, useState } from "react";
import { OpacityTransition } from "components/animations/SnapUp";
import { StepHeader } from "components/installationFlow/steps/StepHeader";
import { classNames } from "helpers/classNames";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const serialNumberRegex = /^E\d{6}$/;
const serialNumberWhileWritingRegex = /^E\d{0,6}$/;

type SerialNumberStepProps = {
  serialNumber?: string;
  setSerialNumber: (serialNumber: string | undefined) => void;
};

export const SerialNumberStep: FC<SerialNumberStepProps> = ({
  serialNumber,
  setSerialNumber,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const { t } = useTranslation();

  // Validates input and sets the serial number if it is valid
  const onSerialNumberInput = (inputString: string) => {
    if (inputString.match(serialNumberRegex)) {
      setSerialNumber(inputString);
      setErrorMessage(undefined);
    } else {
      setSerialNumber(undefined); // Unset serial number. This effects the "Next" button.
      if (inputString.length == 0) {
        setErrorMessage(undefined);
      } else if (inputString.match(serialNumberWhileWritingRegex)) {
        setErrorMessage(undefined);
      } else if (inputString[0] !== "E") {
        setErrorMessage(
          t(`batteryOverview.Serial number starts with a capital E`),
        );
      } else if (inputString.length > 7) {
        setErrorMessage(t(`batteryOverview.Serial number is too long`));
      } else {
        setErrorMessage(
          t(`batteryOverview.Serial number should be on the form EXXXXXX`),
        );
      }
    }
  };

  return (
    <OpacityTransition>
      <StepHeader>
        {t("batteryOverview.Enter the Flower Hub serial number")}
      </StepHeader>
      <div className="flex justify-center">
        <div className="w-4/6 text-base font-normal text-gray-600 text-center">
          <p className="mb-16 text-base text-gray-800">
            {t(
              "batteryOverview.Look for serial on the bottom of the device under the Flower Hub logo",
            )}
          </p>
        </div>
      </div>
      <div>
        <label className="flex text-sm font-medium text-gray-700">
          {t(`batteryOverview.Serial number`)}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            className={classNames(
              "block w-full rounded-md shadow-sm sm:text-sm",
              errorMessage
                ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300"
                : "border-gray-300 focus:border-flowerblue-500 focus:ring-flowerblue-500",
            )}
            autoComplete="off"
            autoFocus={true}
            placeholder="EXXXXXX"
            defaultValue={serialNumber}
            onChange={(e) => onSerialNumberInput(e.target.value)}
          />
          {errorMessage && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {errorMessage && (
          <p className={"mt-2 text-sm text-red-600"}>{errorMessage}</p>
        )}
      </div>
    </OpacityTransition>
  );
};
