import { Steps } from "components/Steps";
import { FC } from "react";
import { getSteps, ViewProps } from "../SignUpFlowPage";
import { StepsComponentContainer } from "./StepsComponentContainer";

export const MobileView: FC<ViewProps> = ({
  currentStep,
  setStep,
  setStartDate,
  startDate,
  invitation,
  assetOwnerDetails,
  setAssetOwnerDetails,
  setPassword,
  passwordIsEntered,
  isCollectCompleted,
  setIsCollectCompleted,
  setCollectResult,
}) => {
  return (
    <div className="h-screen flex flex-col justify-center max-w-full items-center px-4">
      <div className="bg-white shadow-lg py-6 px-6 w-full">
        <Steps steps={getSteps(currentStep)} />
        <StepsComponentContainer
          currentStep={currentStep}
          setStep={setStep}
          setStartDate={setStartDate}
          startDate={startDate}
          invitation={invitation}
          assetOwnerDetails={assetOwnerDetails}
          setAssetOwnerDetails={setAssetOwnerDetails}
          setPassword={setPassword}
          passwordIsEntered={passwordIsEntered}
          isCollectCompleted={isCollectCompleted}
          setIsCollectCompleted={setIsCollectCompleted}
          setCollectResult={setCollectResult}
        />
      </div>
    </div>
  );
};
