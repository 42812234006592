import { SimpleButton } from "components/buttons/SimpleButton";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {}

export const FailedSignUp: FC<Props> = () => {
  const { t } = useTranslation();

  const navigateToStepZero = () => {
    const url = location.href;

    location.assign(url.replace("step=6", "step=0"));
  };

  return (
    <div>
      <p className="text-sm text-gray-500 mt-4">
        {t("bankID.SomethingWrongGoBack")}
      </p>
      <p className="text-sm text-gray-500 mt-4">
        {t("bankID.DoNotReloadPage")}
      </p>
      <SimpleButton
        onClick={navigateToStepZero}
        text={t("BackButton")}
        style="primary"
      />
    </div>
  );
};
