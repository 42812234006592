/* This example requires Tailwind CSS v2.0+ */
import { FC } from "react";
import { AddAssetOwner } from "./AddAssetOwner";
import { UserIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface Props {
  canInviteAssetOwners: boolean;
  installerId: number;
}

export const AssetOwnersEmptyState: FC<Props> = ({
  canInviteAssetOwners,
  installerId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <div className="w-full h-12 flex flex-col justify-center items-center text-center text-gray-4002 mb-4 px-2 sm:px:0">
        <UserIcon />
      </div>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t(`assetOwnerList.No asset owners`)}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {canInviteAssetOwners
          ? t(`assetOwnerList.Get started by creating your first Asset owner`)
          : t(
              `assetOwnerList.Contact your distributor to get permission to invite asset owners`,
            )}
      </p>

      {canInviteAssetOwners && (
        <div className="mt-6">
          <AddAssetOwner
            label={t(`assetOwnerList.Create asset owner`)}
            installerId={installerId}
            centered={true}
          />
        </div>
      )}
    </div>
  );
};
