import { AccountDetails } from "components/assetOwner/AccountDetails";
import { PageWrapperSmall } from "components/PageWrapper";
import { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getAssetOwnerId, getUserRole } from "store/user.store";

export const ProfilePage = () => {
  const userRole = userStore(getUserRole);

  /**
   * Breadcrumbs
   */
  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs(getBreadcrumbs());
  }, []);

  /**
   * Get asset owner
   */
  const assetOwnerId = userStore(getAssetOwnerId) || -1;

  return (
    <PageWrapperSmall>
      <AccountDetails
        assetOwnerId={assetOwnerId}
        userRole={userRole}
        showEditButton={false}
      />
    </PageWrapperSmall>
  );
};

const getBreadcrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Profile,
    to: `${RouteUrl.App}/${RouteUrl.Profile}`,
  },
];
