import { Steps } from "components/Steps";
import { FC } from "react";
import { getSteps, ViewProps } from "../SignUpFlowPage";
import { StepsComponentContainer } from "./StepsComponentContainer";

export const TabletView: FC<ViewProps> = ({
  currentStep,
  setStep,
  setStartDate,
  startDate,
  invitation,
  assetOwnerDetails,
  setAssetOwnerDetails,
  setPassword,
  passwordIsEntered,
  isCollectCompleted,
  setIsCollectCompleted,
  setCollectResult,
}) => (
  <div className="flex flex-col max-w-full items-center flex-1 min-h-screen justify-around">
    <div className="mx-auto w-5/6 shadow-xl bg-white pt-10 pb-6 px-16">
      <Steps steps={getSteps(currentStep)} />
      <StepsComponentContainer
        currentStep={currentStep}
        setStep={setStep}
        setStartDate={setStartDate}
        startDate={startDate}
        invitation={invitation}
        assetOwnerDetails={assetOwnerDetails}
        setAssetOwnerDetails={setAssetOwnerDetails}
        setPassword={setPassword}
        passwordIsEntered={passwordIsEntered}
        isCollectCompleted={isCollectCompleted}
        setIsCollectCompleted={setIsCollectCompleted}
        setCollectResult={setCollectResult}
      />
    </div>
  </div>
);
