import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { UserIcon } from "@heroicons/react/20/solid";
import { Invitation } from "types/invitation.type";
import {
  getRoleName,
  isDistributor,
  isFlower,
  isInstaller,
  User,
} from "api/user/user.type";
import { useInstallerUsers } from "api/installer/installer.endpoints";
import { inviteHasExpired } from "helpers/validation";
import { useTranslation } from "react-i18next";
import { Badge, BadgeColor } from "components/tags/Badge";
import { AddInstallerUser } from "components/installer/AddInstallerUser";
import { DeleteInstallerInvitation } from "./DeleteInstallerInvitation";
import { DeleteInstallerUser } from "./DeleteInstallerUser";
import CustomPlaceholder from "components/CustomPlaceholder";
import { ResentInstallerInvitation } from "./ResentInstallerInvitation";
import { Role } from "enums/role.enum";

interface Props {
  installerId: number;
  userRole: Role;
  userId?: number;
}

export const InstallerUsersTab: FC<Props> = ({
  installerId,
  userRole,
  userId,
}) => {
  const { t } = useTranslation();

  const {
    data: useInstallerUsersResponse,
    isLoading,
    isError,
  } = useInstallerUsers(installerId);

  if (
    isLoading ||
    isError ||
    !useInstallerUsersResponse?.users ||
    !useInstallerUsersResponse.invitations
  ) {
    return (
      <CustomPlaceholder
        isEmpty={
          !isLoading &&
          !isError &&
          !useInstallerUsersResponse?.users &&
          !useInstallerUsersResponse?.invitations
        }
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`installer.Error`)}
        emptyDataMessage={t(`installer.EmptyUser`)}
        loaderSize="page"
      />
    );
  }

  const { users, invitations } = useInstallerUsersResponse;

  const badgeColor = (date: Date): BadgeColor => {
    return inviteHasExpired(date) ? "yellow" : "blue";
  };

  const badgeText = (date: Date): string => {
    return inviteHasExpired(date) ? "Invitation expired" : "Invited";
  };

  return (
    <>
      <div className="overflow-y-auto overflow-x-hidden max-h-screen mb-4">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t(`installer.Email`)}</TH>
              <TH>{t(`installer.Name`)}</TH>
              <TH>{t(`installer.Account type`)}</TH>
              <TH>{t(`installer.Status`)}</TH>
              <TH>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {users.map(({ id, email, role, name }: User) => (
              <TR key={email}>
                <TD addLeftMargin>{email}</TD>
                <TD>{name}</TD>
                <TD>{getRoleName(role)}</TD>
                <TD>
                  <Badge color="green" text="Account created" />
                </TD>
                <TD>
                  {userId != id && isInstaller(userRole) && (
                    <DeleteInstallerUser
                      installerId={installerId}
                      userId={id}
                    />
                  )}
                </TD>
              </TR>
            ))}
            {invitations.map(
              ({ id, email, role, name, expiryDate }: Invitation) => (
                <TR key={id}>
                  <TD addLeftMargin>{email}</TD>
                  <TD>{name}</TD>
                  <TD>{getRoleName(role)}</TD>
                  <TD>
                    <div className="flex">
                      <Badge
                        color={badgeColor(expiryDate)}
                        text={badgeText(expiryDate)}
                      />
                    </div>
                  </TD>
                  <TD>
                    {(isFlower(userRole) || isDistributor(userRole)) && (
                      <ResentInstallerInvitation
                        installerId={installerId}
                        oldInvitationId={id}
                      />
                    )}
                    {isInstaller(userRole) && (
                      <DeleteInstallerInvitation
                        installerId={installerId}
                        invitationId={id}
                      />
                    )}
                  </TD>
                </TR>
              ),
            )}
          </TBody>
        </Table>
      </div>

      {isInstaller(userRole) && <AddInstallerUser installerId={installerId} />}
    </>
  );
};
