import { FC, useMemo, useState } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { GetQualifiedAssetOwnerItemResponseDTO } from "api/bessCompensation/bessCompensation.dto";
import SecondaryButton from "components/buttons/SecondaryButton";
import PostBillingAdjustmentConfirmationModal from "./PostBillingAdjustmentConfirmationModal";
import PostBillingAdjustmentModal from "./PostBillingAdjustmentModal";
import { postBillingAdjustmentAllowedUserIDs } from "constants/billingAdjustments";
import userStore, { getUserId } from "store/user.store";

interface Props {
  assetOwners: GetQualifiedAssetOwnerItemResponseDTO[];
}
export const PostBillingAdjustment: FC<Props> = ({ assetOwners }) => {
  const { t } = useTranslation();
  const userId = userStore(getUserId);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [
    isPostBillingAdjustmentModalOpen,
    setIsPostBillingAdjustmentModalOpen,
  ] = useState(false);

  const shouldShowSendBillingAdjustmentButton = (): boolean => {
    console.log("AAAA", isPostBillingAdjustmentModalOpen);
    const hasNotPostedBillingAdjustments =
      assetOwners.some(
        (customer) => !customer.compensation.billingAdjustmentsHasPosted,
      ) ?? false; // Ensure it defaults to false if undefined

    return (
      hasNotPostedBillingAdjustments &&
      userId !== undefined &&
      postBillingAdjustmentAllowedUserIDs().includes(userId)
    );
  };

  const displayButton = useMemo(
    () => shouldShowSendBillingAdjustmentButton(),
    [assetOwners],
  );

  const startPostingBillingAdjustments = () => {
    setIsConfirmationModalOpen(false);
    console.log("BBBB", isPostBillingAdjustmentModalOpen);
    setTimeout(() => {
      setIsPostBillingAdjustmentModalOpen(true);
    }, 300);
  };

  return (
    <div className="block">
      {displayButton && (
        <SecondaryButton
          onClick={() => setIsConfirmationModalOpen(true)}
          className="max-h-10"
        >
          <PaperAirplaneIcon className="h-5 w-5 mr-1" />{" "}
          {t("billingAdjustment.SendBillingAdjustments")}
        </SecondaryButton>
      )}
      {isConfirmationModalOpen && (
        <PostBillingAdjustmentConfirmationModal
          open={isConfirmationModalOpen}
          closeModal={() => setIsConfirmationModalOpen(false)}
          onOTPVerified={startPostingBillingAdjustments}
        />
      )}
      <PostBillingAdjustmentModal
        open={isPostBillingAdjustmentModalOpen}
        closeModal={() => setIsPostBillingAdjustmentModalOpen(false)}
      />
    </div>
  );
};
