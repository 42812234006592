import { useQuery } from "react-query";
import api from "../axiosInstance";
import { CurrentFlowerHubSystemManagerForAssetResponse } from "./flowerHubSystemManager.dto";

const ENDPOINT = "/flower-hub-system-manager";

export enum FlowerHubStatusQueryKeys {
  GetOneForAsset = "current-flower-hub-system-manager-for-asset",
  GetAllForAsset = "flower-hub-system-manager-for-asset",
}

export const useCurrentFlowerHubSystemManagerForAsset = (assetId: number) =>
  useQuery(
    [FlowerHubStatusQueryKeys.GetOneForAsset, assetId],
    async (): Promise<CurrentFlowerHubSystemManagerForAssetResponse> =>
      (await api.get(`${ENDPOINT}/${assetId}`)).data,
  );

export const useFlowerHubSystemManagerLogsForAsset = (assetId: number) =>
  useQuery(
    [FlowerHubStatusQueryKeys.GetAllForAsset, assetId],
    async (): Promise<CurrentFlowerHubSystemManagerForAssetResponse[]> =>
      (await api.get(`${ENDPOINT}/list/${assetId}`)).data,
  );
