import { useAssetOwners } from "api/assetOwner/assetOwner.endpoints";
import { PageWrapper } from "components/PageWrapper";
import { isEmptyArray } from "helpers/array";
import { FC } from "react";

import { DesktopList } from "./DesktopList";
import { MobileList } from "./MobileList";
import { isInstaller } from "api/user/user.type";
import { useInstaller } from "api/installer/installer.endpoints";
import { AssetOwnersEmptyState } from "components/assetOwner/AssetOwnersEmptyState";
import { useScreenWidth } from "hooks/useScreenWidth";
import { ScreenWidth } from "enums/screenWidth.enum";
import { AddAssetOwner } from "./AddAssetOwner";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { useTranslation } from "react-i18next";
import { getAssetOwnerPageRoute } from "../../helpers/assetOwner";
import { useNavigate } from "react-router-dom";

interface Props {
  userRole: Role;
  distributorId?: number;
  installerId?: number;
}

export const AssetOwnerList: FC<Props> = ({
  userRole,
  distributorId,
  installerId,
}) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const { t } = useTranslation();

  // get assetOwner list
  const {
    data: assetOwners,
    isLoading,
    isError,
  } = useAssetOwners(distributorId, installerId);

  // get installer
  const { data: installer } = useInstaller(installerId);

  const onAssetOwnerClick = (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => {
    navigate(
      getAssetOwnerPageRoute(
        userRole,
        assetOwnerId,
        installerId,
        distributorId,
      ),
    );
  };

  if (isLoading || isError) {
    return (
      <CustomPlaceholder
        isEmpty={false}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`assetOwnerList.Error`)}
        loaderSize="page"
      />
    );
  }

  if (!isLoading && isEmptyArray(assetOwners)) {
    return isInstaller(userRole) && installer ? (
      <AssetOwnersEmptyState
        canInviteAssetOwners={installer.canInviteAssetOwners}
        installerId={installer.id}
      />
    ) : (
      <CustomPlaceholder
        isEmpty={true}
        emptyDataMessage={t(`assetOwnerList.Empty`)}
        isLoading={false}
        isError={false}
        icon={<UserIcon />}
      />
    );
  }

  return (
    <PageWrapper>
      <div>
        {isInstaller(userRole) && installer?.canInviteAssetOwners && (
          <AddAssetOwner
            label={t(`assetOwnerList.Add asset owner`)}
            installerId={installer.id}
          />
        )}
        {screenWidth === ScreenWidth.Mobile ? (
          <MobileList
            assetOwners={assetOwners}
            onAssetOwnerClick={onAssetOwnerClick}
          />
        ) : (
          <DesktopList
            assetOwners={assetOwners}
            onAssetOwnerClick={onAssetOwnerClick}
            userRole={userRole}
          />
        )}
      </div>
    </PageWrapper>
  );
};
