export interface Manufacturer {
  label: string;
  dbEntry: string;
  assetModels: AssetModel[];
}

export interface AssetModel {
  label: string;
  dbEntryId: number;
  modelConfigurations: AssetModelConfiguration[];
}

export interface AssetModelConfiguration {
  ratedEnergy: number;
  ratedPower: number;
}

const sungrowShortBydHvs: AssetModel = {
  label: "SH*.0RT/BYD HVS",
  dbEntryId: 4,
  modelConfigurations: [
    {
      ratedEnergy: 12.8,
      ratedPower: 12.8,
    },
    {
      ratedEnergy: 10.2,
      ratedPower: 10.24,
    },
    {
      ratedEnergy: 7.7,
      ratedPower: 7.68,
    },
    {
      ratedEnergy: 5.1,
      ratedPower: 5.1,
    },
  ],
};

const sungrowShortByd: AssetModel = {
  label: "SH*.0RT/SBR",
  dbEntryId: 2,
  modelConfigurations: [
    {
      ratedEnergy: 25.6,
      ratedPower: 17.52,
    },
    {
      ratedEnergy: 22.4,
      ratedPower: 15.33,
    },
    {
      ratedEnergy: 19.2,
      ratedPower: 13.14,
    },
    {
      ratedEnergy: 16,
      ratedPower: 10.95,
    },
    {
      ratedEnergy: 12.8,
      ratedPower: 8.67,
    },
    {
      ratedEnergy: 9.6,
      ratedPower: 6.57,
    },
  ],
};

const sajH2KT2B2Hv1: AssetModel = {
  label: "H2-*K-T2/B2-HV-1",
  dbEntryId: 3,
  modelConfigurations: [
    {
      ratedEnergy: 25,
      ratedPower: 10,
    },
    {
      ratedEnergy: 20,
      ratedPower: 10,
    },
    {
      ratedEnergy: 15,
      ratedPower: 9,
    },
    {
      ratedEnergy: 10,
      ratedPower: 6,
    },
  ],
};

const huaweiSun2000Luna2000: AssetModel = {
  label: "SUN2000/LUNA2000",
  dbEntryId: 1,
  modelConfigurations: [
    {
      ratedEnergy: 15,
      ratedPower: 5,
    },
    {
      ratedEnergy: 10,
      ratedPower: 5,
    },
    {
      ratedEnergy: 5,
      ratedPower: 2.5,
    },
  ],
};

const sungrow: Manufacturer = {
  label: "Sungrow",
  dbEntry: "Sungrow",
  assetModels: [sungrowShortBydHvs, sungrowShortByd],
};

const saj: Manufacturer = {
  label: "SAJ",
  dbEntry: "SAJ",
  assetModels: [sajH2KT2B2Hv1],
};

const huawei: Manufacturer = {
  label: "Huawei",
  dbEntry: "Huawei",
  assetModels: [huaweiSun2000Luna2000],
};

export const manufacturers: Manufacturer[] = [sungrow, saj, huawei];

export interface Fuse {
  ampere: number;
}

export const fuses: Fuse[] = [
  {
    ampere: 16,
  },
  {
    ampere: 20,
  },
  {
    ampere: 25,
  },
  {
    ampere: 35,
  },
  {
    ampere: 50,
  },
  {
    ampere: 63,
  },
];
