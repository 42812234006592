import { OpacityTransition } from "components/animations/SnapUp";
import { StepHeader } from "components/installationFlow/steps/StepHeader";
import { useTranslation } from "react-i18next";

export const ConnectedEthStep = () => {
  const { t } = useTranslation();

  return (
    <OpacityTransition>
      <StepHeader>{t("Start installing")}</StepHeader>
      <div className="flex justify-center">
        <div className="text-base w-9/12 font-normal text-gray-600">
          <ul className="list-disc pl-8 text-base text-gray-800 pt-2">
            <li className="mb-4 text-base text-gray-800">
              {t(
                `batteryOverview.Do not plug in the power cable yet, you will do that in a future step`,
              )}
            </li>
            <li>
              {t(
                "batteryOverview.Connect the Flower Hub with an Ethernet cable to the same network as the inverter",
              )}
            </li>
          </ul>
        </div>
      </div>
    </OpacityTransition>
  );
};
