import { FC, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { usePostBillingAdjustments } from "api/bessCompensation/bessCompensation.endpoints";
import { FlowerSymbolLogo } from "styles/Icons";
import { debounce } from "lodash";

interface PostBillingAdjustmentModalProps {
  open: boolean;
  closeModal: () => void;
}

const PostBillingAdjustmentModal: FC<PostBillingAdjustmentModalProps> = ({
  open,
  closeModal,
}) => {
  const { t } = useTranslation();

  const {
    mutate: sendAdjustment,
    isLoading,
    isError,
    isSuccess,
  } = usePostBillingAdjustments();

  useEffect(() => {
    const handleAdjustment = debounce(() => {
      if (open) {
        sendAdjustment();
      }
    }, 300); // 1000ms debounce

    handleAdjustment();

    return () => {
      handleAdjustment.cancel();
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={() => {}} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative z-10 flex flex-col justify-between overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 min-h-96 bg-white"
          >
            <div className="flex-grow flex flex-col items-center justify-center">
              <div className="flex flex-shrink-0 items-center justify-center my-4">
                <img
                  className={`h-auto w-28`}
                  src={FlowerSymbolLogo}
                  alt="Flower Hub"
                />
              </div>
              <div className="text-center">
                <DialogTitle
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-600"
                >
                  <p>
                    {isLoading &&
                      t("billingAdjustment.Sending post billing adjustment")}
                    {isSuccess &&
                      t("billingAdjustment.Adjustment sent successfully")}
                    {isError &&
                      t("billingAdjustment.Post billing adjustments failed")}
                  </p>
                </DialogTitle>
              </div>
            </div>

            <div className="mt-5">
              {!isLoading && (
                <button
                  type="button"
                  onClick={closeModal}
                  className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  {t("Close")}
                </button>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default PostBillingAdjustmentModal;
