import { FC } from "react";
import CustomPlaceholder from "components/CustomPlaceholder";
import { useFlowerHubLogsForAsset } from "api/flowerHubLog/flowerHubLog.endpoints";
import { FlowerHubLog } from "./FlowerHubLog";
import { Panel } from "components/Panel";
import { useTranslation } from "react-i18next";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

interface Props {
  assetId: number;
  serialNumber: string;
}

export const FlowerHubLogs: FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { data, isError, isLoading } = useFlowerHubLogsForAsset(assetId);

  return (
    <Panel header={t(`flowerHubLogs.Title`)} infoText={t(`flowerHubLogs.Info`)}>
      <div className="flow-root w-full pl-3 pr-3 overflow-y-auto">
        {isLoading || data?.length === 0 || isError ? (
          <CustomPlaceholder
            isError={isError}
            isLoading={isLoading}
            isEmpty={!isLoading && !isError && data?.length === 0}
            icon={<ClipboardDocumentListIcon />}
            errorMessage={t(`flowerHubLogs.UnableToLoad`)}
            emptyDataMessage={t(`flowerHubLogs.NotFound`)}
          />
        ) : (
          <ul role="list" className="max-h-80 -mb-8">
            {data?.map((status) => (
              <FlowerHubLog
                key={status.id}
                type={status.type}
                log={status.log}
                isResolved={status.isResolved}
                reportedAt={status.reportedAt}
              />
            ))}
          </ul>
        )}
      </div>
    </Panel>
  );
};
