import { Distributor } from "api/distributor/distributor.type";
import { Item, Label, List, Value } from "components/descriptionList";
import { Shadow } from "components/Shadow";
import TabContainer from "components/tab/TabContainer";
import { FC } from "react";

interface Props {
  distributor: Distributor;
}

export const AccountDetails: FC<Props> = ({ distributor }) => {
  return (
    <TabContainer>
      <Shadow>
        <List>
          <Item>
            <Label>Company name</Label>
            <Value>{distributor.name}</Value>
          </Item>
          <Item>
            <Label>Organization number</Label>
            <Value>{distributor.organizationNumber}</Value>
          </Item>
          <Item>
            <Label>Address</Label>
            <Value>{distributor.address}</Value>
          </Item>
          <Item>
            <Label>Postal code</Label>
            <Value>{distributor.postalCode}</Value>
          </Item>
          <Item>
            <Label>City</Label>
            <Value>{distributor.city}</Value>
          </Item>
          <Item>
            <Label>Bankgiro / postgiro</Label>
            <Value>{distributor.giroNumber}</Value>
          </Item>
        </List>
      </Shadow>
    </TabContainer>
  );
};
