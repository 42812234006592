import { Transition } from "components/animations/Transition";
import { classNames } from "helpers/classNames";
import * as React from "react";
import { config } from "react-spring";

interface Props {
  children: React.ReactNode;
  className?: string;
}
const TabContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <Transition
      settings={{
        from: { opacity: 0.7 },
        enter: { opacity: 1 },
        config: { ...config.default, precision: 0.01 },
      }}
    >
      <div className={classNames("relative", className ?? false)}>
        {children}
      </div>
    </Transition>
  );
};

export default TabContainer;
