import { FC } from "react";
import { ViewProps } from "../SignUpFlowPage";
import { Container } from "./Container";
import { UserDetails } from "pages/sign-up-flow/steps/UserDetails";
import { ElectricityContract } from "pages/sign-up-flow/steps/ElectricityContract";
import { FinalStep } from "pages/sign-up-flow/steps/FinalStep";
import { TermsAndConditions } from "./TermsAndConditions";
import { Landing } from "pages/sign-up-flow/steps/Landing";
import { ChoosePassword } from "pages/sign-up-flow/steps/ChoosePassword";
import { FlowerHubLogo } from "styles/Icons";
import { BankIDVerification } from "pages/sign-up-flow/steps/BankIDVerification";
import { SignUpStep } from "enums/signUpStep.enum";
import { SameUserWarning } from "pages/sign-up-flow/steps/SameUserWarning";
import {
  getAddressOnlyAssetDetailsFromInvitation,
  getFullAssetDetailsFromInvitation,
} from "api/assetOwnerInvitation/assetOwnerInvitation.helper";

export const StepsComponentContainer: FC<ViewProps> = ({
  currentStep,
  setStep,
  setStartDate,
  startDate,
  invitation,
  assetOwnerDetails,
  setAssetOwnerDetails,
  setPassword,
  passwordIsEntered,
  isCollectCompleted,
  setIsCollectCompleted,
  setCollectResult,
}) => (
  <Container>
    <div className="px-6 py-6">
      <img
        className="h-16 w-auto mx-auto"
        alt="FlowerHub logo"
        src={FlowerHubLogo}
      />
    </div>
    {currentStep === SignUpStep.Landing && (
      <Landing
        invitation={invitation}
        onSamePersonSelectedContinue={() => {
          setAssetOwnerDetails(getFullAssetDetailsFromInvitation(invitation));
          setStep(SignUpStep.UserDetails);
        }}
        onAnotherPersonSelectedContinue={() => {
          setAssetOwnerDetails(
            getAddressOnlyAssetDetailsFromInvitation(invitation),
          );
          setStep(SignUpStep.SamePersonWarning);
        }}
      />
    )}
    {currentStep === SignUpStep.SamePersonWarning && (
      <SameUserWarning setStep={setStep} />
    )}
    {currentStep === SignUpStep.UserDetails && (
      <UserDetails
        invitation={invitation}
        assetOwnerDetails={assetOwnerDetails}
        onBackButtonPressed={() => setStep(SignUpStep.Landing)}
        onUserDetailsFormCompleted={(assetOwnerDetails) => {
          setAssetOwnerDetails(assetOwnerDetails);
          setStep(SignUpStep.ElectricityContract);
        }}
      />
    )}
    {currentStep === SignUpStep.ElectricityContract && (
      <ElectricityContract
        onStartDateChosen={(startDate) => {
          setStartDate(startDate);
          setStep(SignUpStep.Terms);
        }}
        onBackButtonClicked={() => setStep(SignUpStep.UserDetails)}
        startDate={startDate}
      />
    )}
    {currentStep === SignUpStep.Terms && (
      <TermsAndConditions
        onBackButtonClicked={() => setStep(SignUpStep.ElectricityContract)}
        onNextButtonClicked={() => setStep(SignUpStep.ChoosePassword)}
      />
    )}
    {currentStep === SignUpStep.ChoosePassword && (
      <ChoosePassword
        onPasswordConfirmed={(password) => {
          setPassword(password);
          setStep(SignUpStep.BankID);
        }}
        invitation={invitation}
      />
    )}
    {currentStep === SignUpStep.BankID && (
      <BankIDVerification
        assetOwnerDetails={assetOwnerDetails}
        passwordIsEntered={passwordIsEntered}
        isCollectCompleted={isCollectCompleted}
        setIsCollectCompleted={setIsCollectCompleted}
        setCollectResult={setCollectResult}
      />
    )}
    {currentStep === SignUpStep.Final && (
      <FinalStep handleClick={() => (window.location.href = "login")} />
    )}
  </Container>
);
