import { classNames } from "helpers/classNames";
import React, { FC } from "react";

export const Item: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className = "",
}) => (
  <div
    className={classNames(
      "py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 items-center",
      className,
    )}
  >
    {children}
  </div>
);
