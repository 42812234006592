import { CheckIcon } from "@heroicons/react/20/solid";
import { FlowerHubProgressStage } from "enums/flowerHubStatus.enum";
import { FC } from "react";

interface StageIndicatorProp {
  stage: FlowerHubProgressStage;
  stepIdx: number;
}
export const StageIndicator: FC<StageIndicatorProp> = ({ stage, stepIdx }) => {
  switch (stage) {
    case FlowerHubProgressStage.Completed:
      return (
        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-flowerSky-500">
          <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </span>
      );
    case FlowerHubProgressStage.Current:
      return (
        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-flowerSky-500">
          <span className="text-flowerSky-500">{stepIdx + 1}</span>
        </span>
      );
    case FlowerHubProgressStage.Upcoming:
      return (
        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
          <span className="text-gray-500">{stepIdx + 1}</span>
        </span>
      );
    default:
      return null;
  }
};
