import { useQuery } from "react-query";
import api from "../axiosInstance";
import { GetFlowerHubLogForAssetDTO } from "./flowerHubLog.dto";

const ENDPOINT = "/flower-hub-log";

export enum FlowerHubLogQueryKeys {
  GetAllForAsset = "flower-hub-log-for-asset",
}

export const useFlowerHubLogsForAsset = (assetId: number) =>
  useQuery(
    [FlowerHubLogQueryKeys.GetAllForAsset, assetId],
    async (): Promise<GetFlowerHubLogForAssetDTO[]> =>
      (await api.get(`${ENDPOINT}/${assetId}`)).data,
    {
      cacheTime: 0,
    },
  );
