import { useAssets } from "api/asset/asset.endpoints";
import { isEmptyArray } from "helpers/array";
import { useMemo } from "react";

import CustomPlaceholder from "components/CustomPlaceholder";
import { ServerIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { FlowerHubStatusTag } from "components/tags/FlowerHubStatusTag";
import { TableOptions } from "@tanstack/react-table";
import {
  FlowerHubStatusDTO,
  GetAssetItemResponseDTO,
} from "api/asset/asset.dto";
import {
  Filter,
  filterFunction,
  getNestedCombinedFilterTags,
  getUniqueFilterTags,
} from "components/filters/Filters";
import { TanStackTable } from "components/table/TanStackTable";
import { useNavigate } from "react-router-dom";
import { getAssetRoute } from "../../helpers/assetOwner";

export const FlowerHubList = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  // asset list
  const { data: assets, isLoading, isError } = useAssets();

  const onAssetClick = (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => {
    navigate(getAssetRoute(assetOwnerId, installerId, distributorId));
  };

  const columns: TableOptions<GetAssetItemResponseDTO>["columns"] = useMemo(
    () => [
      {
        accessorKey: "serialNumber",
        header: "Serial number",
        filterFn: "includesString",
      },
      {
        accessorKey: "flowerHubStatus",
        accessorFn: (row: GetAssetItemResponseDTO) =>
          row?.flowerHubStatus?.status,
        header: "Flower Hub ",
        cell: (row) => {
          return (
            <FlowerHubStatusTag
              flowerHubStatus={
                row.row.original?.flowerHubStatus as FlowerHubStatusDTO
              }
              isFlowerUser={true}
            />
          );
        },
        filterFn: filterFunction,
      },
      {
        accessorKey: "model",
        accessorFn: (row: GetAssetItemResponseDTO) =>
          `${row.assetModel.manufacturer} ${row.assetModel.name}`,
        header: "Model",
        filterFn: filterFunction,
      },
      {
        accessorKey: "bessPowerCapacity",
        accessorFn: (row: GetAssetItemResponseDTO) =>
          `${row.bessPowerCapacity} kW`,
        header: "Power capacity",
        filterFn: "includesString",
      },
      {
        accessorKey: "bessCapacity",
        accessorFn: (row: GetAssetItemResponseDTO) => `${row.bessCapacity} kWh`,
        header: "Capacity",
        filterFn: "includesString",
      },
      {
        accessorKey: "inverterPowerCapacity",
        accessorFn: (row: GetAssetItemResponseDTO) =>
          `${row.inverterPowerCapacity} kW`,
        header: "Inverter P.C",
        filterFn: "includesString",
      },
      {
        accessorKey: "installer.name",
        header: "Installer Name",
        filterFn: "includesString",
      },
    ],
    [assets],
  );

  const filters: Filter[] = [
    {
      id: "flowerHubStatus",
      name: "Flower Hub",
      options: getUniqueFilterTags({
        data: assets ?? [],
        columnName: "flowerHubStatus",
        firstNestedProperty: "status",
      }),
    },
    {
      id: "model",
      name: "Model",
      options: getNestedCombinedFilterTags({
        data: assets ?? [],
        columnName: "assetModel",
        firstProperty: "manufacturer",
        secondProperty: "name",
      }),
    },
  ];

  if (isLoading || isEmptyArray(assets) || isError) {
    return (
      <CustomPlaceholder
        isError={isError}
        isLoading={isLoading}
        isEmpty={!isLoading && !isError && isEmptyArray(assets)}
        icon={<ServerIcon />}
        errorMessage={t(`flowerHub.UnableToLoadFlowerHubs`)}
        emptyDataMessage={t(`flowerHub.NoFlowerHubs`)}
        loaderSize="page"
      />
    );
  }

  return (
    <div className="block">
      <TanStackTable
        columns={columns}
        data={assets ?? []}
        filters={filters}
        onSelectRow={(row: GetAssetItemResponseDTO) =>
          onAssetClick(row.assetOwnerId, row.installer.id, row.distributorId)
        }
      />
    </div>
  );
};
