import React, { FC } from "react";
import { SmoothUp } from "components/animations/SnapUp";
import { SmallLoader } from "components/loaders/SmallLoader";
import { MediumLoader } from "./loaders/MediumLoader";
import { PageLoader } from "./loaders/PageLoader";

interface Props {
  isEmpty: boolean;
  isLoading: boolean;
  isError: boolean;
  icon: React.ReactNode;
  errorMessage?: string;
  emptyDataMessage?: string;
  loaderSize?: "small" | "medium" | "page";
}

const CustomPlaceholder: FC<Props> = ({
  isEmpty,
  isLoading,
  isError,
  icon,
  errorMessage,
  emptyDataMessage,
  loaderSize = "small",
}) => {
  const getLoaderForSize = (size: "small" | "medium" | "page") => {
    switch (size) {
      case "page":
        return <PageLoader />;
      case "medium":
        return <MediumLoader />;
      case "small":
      default:
        return <SmallLoader />;
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center text-center mb-4 px-2 sm:px:0">
      {isLoading ? (
        <SmoothUp>{getLoaderForSize(loaderSize)}</SmoothUp>
      ) : (
        <div className="flex justify-center items-center w-12 h-12 sm:w-12 sm:h-12 text-gray-400">
          {icon}
        </div>
      )}

      {isError && (
        <h3 className="mt-2 text-sm font-medium text-gray-600">
          {errorMessage}
        </h3>
      )}
      {isEmpty && emptyDataMessage && (
        <h3 className="mt-2 text-sm font-medium text-gray-600">
          {emptyDataMessage}
        </h3>
      )}
    </div>
  );
};

export default CustomPlaceholder;
