import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  FlowerHubProgressContext,
  FlowerHubProgressStage,
} from "enums/flowerHubStatus.enum";
import { classNames } from "helpers/classNames";

interface StepLabelProp {
  stage: FlowerHubProgressStage;
  step: FlowerHubProgressContext;
}

export const StepLabel: FC<StepLabelProp> = ({ stage, step }) => {
  const { t } = useTranslation();
  let labelKey = "";

  switch (stage) {
    case FlowerHubProgressStage.Completed:
      labelKey = `flowerHubProgress.pastProgressStep.${step}`;
      break;
    case FlowerHubProgressStage.Current:
      labelKey = `flowerHubProgress.presentParticipleProgressStep.${step}`;
      break;
    case FlowerHubProgressStage.Upcoming:
      labelKey = `flowerHubProgress.presentProgressStep.${step}`;
      break;
  }

  return (
    <span
      className={classNames(
        stage === FlowerHubProgressStage.Current
          ? "text-flowerSky-500"
          : stage === FlowerHubProgressStage.Upcoming
            ? "text-gray-500"
            : "",
        "text-sm font-medium",
      )}
    >
      {t(labelKey)}
    </span>
  );
};
