import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery } from "react-query";
import api from "../axiosInstance";
import { User } from "./user.type";

const ENDPOINT = "user";

export enum UserQueryKeys {
  GetUsers = "user-get-users",
  GetFlowerUsers = "user-get-flower-users",
}

export const useUsers = (options = {}) =>
  useQuery(
    [UserQueryKeys.GetUsers],
    async (): Promise<User[]> => (await api.get(ENDPOINT)).data,
    options,
  );

export const useFlowerUsers = () =>
  useQuery(
    [UserQueryKeys.GetFlowerUsers],
    async (): Promise<User[]> => (await api.get(`${ENDPOINT}/flower`)).data,
  );

export const useForgotPassword = () => {
  return useMutation(
    async (payload: ForgotPasswordDTO): Promise<void> =>
      await api.post(`${ENDPOINT}/forgot-password`, payload),
  );
};

export const useResetPassword = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: ResetPasswordDTO) =>
      await api.put(`${ENDPOINT}/reset-password`, payload),
    {
      onError: () => {
        notification.error();
      },
    },
  );
};

export const useDeactivateUser = (settings = {}) => {
  const notification = useNotification();
  return useMutation(
    async (id: number): Promise<{ id: number }> =>
      await api.delete(`${ENDPOINT}/${id}`),
    {
      onError: () => {
        notification.error();
      },
      ...settings,
    },
  );
};

export type ForgotPasswordDTO = {
  email: string;
};

export type ResetPasswordDTO = {
  password: string;
  code: string;
};

export const isPasswordStrongEnough = (password: string) =>
  (password ?? "").length >= 10;
