/* cspell:disable-next-line */
import { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getDistributorId, getUserRole } from "store/user.store";

import { InstallerList } from "components/installer/InstallerList";

export const InstallerListPage = () => {
  const userRole = userStore(getUserRole);
  const distributorId = userStore(getDistributorId) ?? -1;

  // breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs([getInstallerListBreadcrumb()]);
  }, []);

  return <InstallerList userRole={userRole} distributorId={distributorId} />;
};

export const getInstallerListBreadcrumb = (): BreadCrumb => ({
  label: RouteLabel.Installers,
  to: `${RouteUrl.App}/${RouteUrl.Installers}`,
});
