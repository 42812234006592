//Server side enum for status progress
export enum FlowerHubProgressContext {
  Register = "Register",
  Initialize = "Initialize",
  Install = "Install",
  Deploy = "Deploy",
  Connect = "Connect",
  Communicate = "Communicate",
}

export enum FlowerHubProgressStage {
  Completed = "Completed",
  Current = "Current",
  Upcoming = "Upcoming",
}

export enum FlowerHubStatusMessageCode {
  //Flower hub installation and requirement related message codes
  FlowerHubNotInstalled = "FlowerHubNotInstalled",
  WaitingToCreateIoTThing = "WaitingToCreateIoTThing",

  // Green grass 'thing' related message codes
  AbleToFindIoTThing = "AbleToFindIoTThing", // Won't return to client
  UnableToFindIoTThing = "UnableToFindIoTThing",

  // Green grass 'core device' related message codes
  GreengrassCoreDeviceIsHealthy = "GreengrassCoreDeviceIsHealthy", // Won't return to client
  GreengrassCoreDeviceIsUnhealthy = "GreengrassCoreDeviceIsUnhealthy", // Won't return to client
  UnableToFindGreengrassCoreDevice = "UnableToFindGreengrassCoreDevice",

  // Green grass 'deployments' related message codes
  GreengrassDeploymentSucceeded = "GreengrassDeploymentSucceeded", // Won't return to client
  GreengrassDeploymentInProgress = "GreengrassDeploymentInProgress",
  GreengrassDeploymentFailed = "GreengrassDeploymentFailed",
  UnableToFindGreengrassDeployment = "UnableToFindGreengrassDeployment",
  GreengrassDeploymentUnknownStatus = "GreengrassDeploymentUnknownStatus",

  // Green grass 'components' related message codes
  ComponentsAreRunning = "ComponentsAreRunning", // Won't return to client
  OtherComponentErrored = "OtherComponentErrored",
  M2MComponentErrored = "M2MComponentErrored",
  UnableToFindGreengrassComponents = "UnableToFindGreengrassComponents",

  // Failure manager related message codes
  CannotFindDongle = "CannotFindDongle",

  // All done and communicating
  ComponentsAreRunningAndCommunicating = "ComponentsAreRunningAndCommunicating",
}

export enum FlowerHubStatus {
  Communicating = "Communicating",
  CommunicationError = "Communication error",
  DeviceError = "Device error",
  DeploymentError = "Deployment Error",
  DeploymentInProgress = "Deployment in progress",
  InstallationFailed = "Installation failed",
  Registering = "Registering",
}
