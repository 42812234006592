export const positiveNumberValidator = {
  isNumeric: true,
  validate: (value: number) => {
    return (
      (!isNaN(value) && value > 0) || "Please enter a number bigger than 0"
    );
  },
};

export const noEmptyStringValidator = (
  errorMessage = "Please fill in this field",
) => ({
  required: errorMessage,
});

export const inviteHasExpired = (expiryDate: Date) => {
  const today = new Date();
  return new Date(expiryDate) < today;
};

export const emailValidator = {
  required: "Please fill in this field",
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "Invalid email format",
  },
};

export const passwordValidator = {
  required: "Please fill in this field",
  pattern: {
    value: /^(.{10}).*$/,
    message: "The password needs to be at least 10 characters long",
  },
};
