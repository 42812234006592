import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import DangerButton from "components/buttons/DangerButton";
import SecondaryButton from "components/buttons/SecondaryButton";
import { isEmptyArray } from "helpers/array";
import EmptyState from "components/EmptyState";
import userStore, { getUserId } from "store/user.store";
import { Shadow } from "components/Shadow";
import { Badge } from "components/tags/Badge";
import { getRoleName, User } from "api/user/user.type";
import { GetFlowerInvitationItemDTO } from "../../../api/flowerInvitation/flowerInvitation.dto";
import { Role } from "enums/role.enum";

interface Props {
  users?: User[];
  invitations?: GetFlowerInvitationItemDTO[];
  deleteInvitation?: (id: number) => void;
  deactivateUser: (id: number) => void;
  isFlower?: boolean;
}

export const UsersTab: FC<Props> = ({
  users = [],
  invitations = [],
  deleteInvitation = () => null,
  deactivateUser,
  isFlower,
}) => {
  if (isEmptyArray(users) && isEmptyArray(invitations)) {
    return (
      <EmptyState
        header="No Flower users"
        subHeader="No Flower user have been added yet"
      />
    );
  }
  const loggedInUserId = userStore(getUserId);
  return (
    <Shadow>
      <Table>
        <THead>
          <TR>
            <TH isFirstColumn>Email</TH>
            <TH>Name</TH>
            <TH>Status</TH>
            <TH>Role</TH>
            <TH>
              <></>
            </TH>
          </TR>
        </THead>
        <TBody>
          {users.map(({ email, role, id, name }: User) => (
            <TR key={email}>
              <TD addLeftMargin>{email}</TD>
              <TD>{name}</TD>
              <TD>
                <Badge color="green" text="Account created" />
              </TD>
              <TD>{getRoleName(role)}</TD>
              <TD alignRight>
                {loggedInUserId != id && (
                  <DangerButton size="small" onClick={() => deactivateUser(id)}>
                    Deactivate user
                  </DangerButton>
                )}
              </TD>
            </TR>
          ))}
          {invitations.map(
            ({ email, id, role, name }: GetFlowerInvitationItemDTO) => (
              <TR key={id}>
                <TD addLeftMargin>{email}</TD>
                <TD>{name}</TD>
                <TD>
                  <Badge color="blue" text="Invited" />
                </TD>
                <TD>{getRoleName(isFlower ? Role.Flower : role)}</TD>
                <TD alignRight>
                  <SecondaryButton
                    size="small"
                    onClick={() => deleteInvitation(id)}
                  >
                    Delete invitation
                  </SecondaryButton>
                </TD>
              </TR>
            ),
          )}
        </TBody>
      </Table>
    </Shadow>
  );
};
