import { AddressDTO } from "api/shared/address.dto";

export type CreateInstallerDTO = {
  distributorId: number;
  name: string;
  organizationNumber: string;
  firstUserEmail: string;
};

export const getEmptyCreateInstallerDTO = (): CreateInstallerDTO => ({
  distributorId: -1,
  name: "",
  organizationNumber: "",
  firstUserEmail: "",
});

export type UpdateInstallerDTO = {
  name?: string;
  organizationNumber?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  giroNumber?: string;
};

export type UpdateInstallerCanInviteAssetOwnersDTO = {
  canInviteAssetOwners: boolean;
};

export type InstallerContactDTO = {
  id: number;
  name: string;
  address: AddressDTO;
};
