import { useEffect } from "react";
import { menuStore } from "store/menu.store";
import { FlowerHubLogo } from "styles/Icons";

export const NotFoundPage = () => {
  /**
   * Breadcrumbs
   */
  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs([]);
  }, []);

  return (
    <div className="min-h-full pl-8 py-14 sm:py-14 space-y-4 sm:space-y-8">
      <img className="h-16 w-auto" src={FlowerHubLogo} />

      <main className="sm:flex">
        <p className="text-4xl font-bold tracking-tight sm:text-5xl text-gray-700">
          404
        </p>
        <div className="sm:ml-6">
          <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl">
              Page not found
            </h1>
            <p className="mt-1 text-base text-gray-500">
              Please check the URL in the address bar and try again.
            </p>
          </div>
          <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"></div>
        </div>
      </main>
    </div>
  );
};
