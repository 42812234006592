import { StepStatus } from "components/Steps";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useAssetOwnerInvitation,
  useAssetOwnerSignUp,
} from "api/assetOwnerInvitation/assetOwnerInvitation.endpoints";
import { SmoothUp } from "components/animations/SnapUp";
import { useScreenWidth } from "hooks/useScreenWidth";
import { DesktopView } from "./components/DesktopView";
import { PageLoader } from "components/loaders/PageLoader";
import { NotFound } from "components/NotFound";
import { ScreenWidth } from "enums/screenWidth.enum";
import { SignUpStep } from "enums/signUpStep.enum";
import { useTranslation } from "react-i18next";
import { Language } from "i18n/i18n.constants";
import { useActiveZavannSystemNotificationsByCode } from "api/system-notification/systemNotification.endpoints";
import { MaintenanceScreen } from "components/systemNotification/MaintenanceScreen";
import { MobileView } from "./components/MobileView";
import { TabletView } from "./components/TabletView";
import {
  AssetOwnerDetailsDTO,
  GetAssetOwnerInvitationResponseDTO,
} from "../../api/assetOwnerInvitation/assetOwnerInvitation.dto";
import { CollectResponseDTO } from "../../api/zavann/zavann.dto";
import { getEmptyAssetOwnerDetails } from "api/assetOwnerInvitation/assetOwnerInvitation.helper";

export const SignUpFlowPage = () => {
  /**
   * Set language
   */
  const { i18n } = useTranslation();
  if (i18n.language !== Language.Swedish) {
    i18n.changeLanguage(Language.Swedish);
  }

  /**
   * Set step
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const setStep = (step: SignUpStep) => {
    searchParams.set("step", step.toString());
    setSearchParams(searchParams);
  };
  const currentStep = +(searchParams.get("step") || SignUpStep.Landing);
  const code = searchParams.get("code") || "-1";

  /**
   * Get invitation
   */
  const {
    data: invitation,
    isError,
    isLoading: isLoadingInvitation,
  } = useAssetOwnerInvitation(code, currentStep < SignUpStep.Final);

  /**
   * Get empty asset owner details
   */
  const [assetOwnerDetails, setAssetOwnerDetails] =
    useState<AssetOwnerDetailsDTO>(getEmptyAssetOwnerDetails());

  /**
   * Get active zavann system notifications
   */
  const { data: systemNotification } =
    useActiveZavannSystemNotificationsByCode(code);

  /**
   * Start date
   */
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
  );

  /**
   * Password
   */
  const [password, setPassword] = useState("");
  // Used instead of sending password in the final step
  const [passwordIsEntered, setPasswordIsEntered] = useState(false);

  useEffect(() => {
    if (password.length > 0) {
      setPasswordIsEntered(true);
    }
  }, [password]);

  /**
   * BankID status
   */
  const [isCollectCompleted, setIsCollectCompleted] = useState<boolean>(false);
  const [collectResult, setCollectResult] = useState<CollectResponseDTO>();

  /**
   * Complete sign up
   */
  const {
    mutate: completeSignUp,
    isSuccess: isSignUpSuccess,
    isError: isSignUpError,
  } = useAssetOwnerSignUp();

  useEffect(() => {
    if (isCollectCompleted) {
      if (!assetOwnerDetails) {
        throw Error("Missing asset owner details");
      }

      if (!startDate) {
        throw Error("Missing electricity contract starting date");
      }

      if (!collectResult) {
        throw Error("Missing BankID details");
      }

      const bankIdDetails = {
        bankIdOrderRef: collectResult.orderRef,
        bankIdIp: collectResult.completionData.device.ipAddress,
        bankIdName: collectResult.completionData.user.name,
        bankIdStatus: collectResult.status,
      };

      assetOwnerDetails.personalNumber =
        collectResult.completionData.user.personalNumber;

      completeSignUp({
        code,
        password,
        assetOwnerDetails,
        bankIdDetails,
        startDate: startDate.toISOString(),
      });
    }
  }, [isCollectCompleted]);

  useEffect(() => {
    if (isSignUpSuccess) {
      setStep(SignUpStep.Final);
    }
  }, [isSignUpSuccess]);

  const screenWidth = useScreenWidth();

  if (isLoadingInvitation) {
    return <PageLoader />;
  }

  if (isError || !invitation) {
    return (
      <NotFound text="This link is either incorrect or has expired. If you have already created an account and forgotten your password, you can reset it." />
    );
  }

  if (systemNotification) {
    return (
      <MaintenanceScreen text="Account creation unavailable due to system maintenance. Please try again later!" />
    );
  }

  if (isSignUpError) {
    return (
      <NotFound
        errorCode="500"
        header="Something went wrong."
        text="We are currently experiencing problem with our servers. Please try again."
        link={`?code=${code}`}
        linkText="Start over"
      />
    );
  }

  return (
    <SmoothUp>
      {screenWidth === ScreenWidth.Mobile && (
        <MobileView
          currentStep={currentStep}
          setStep={setStep}
          invitation={invitation}
          setStartDate={setStartDate}
          startDate={startDate}
          assetOwnerDetails={assetOwnerDetails}
          setAssetOwnerDetails={setAssetOwnerDetails}
          setPassword={setPassword}
          passwordIsEntered={passwordIsEntered}
          isCollectCompleted={isCollectCompleted}
          setIsCollectCompleted={setIsCollectCompleted}
          setCollectResult={setCollectResult}
        />
      )}
      {screenWidth === ScreenWidth.Tablet && (
        <TabletView
          currentStep={currentStep}
          setStep={setStep}
          invitation={invitation}
          setStartDate={setStartDate}
          startDate={startDate}
          assetOwnerDetails={assetOwnerDetails}
          setAssetOwnerDetails={setAssetOwnerDetails}
          setPassword={setPassword}
          passwordIsEntered={passwordIsEntered}
          isCollectCompleted={isCollectCompleted}
          setIsCollectCompleted={setIsCollectCompleted}
          setCollectResult={setCollectResult}
        />
      )}
      {screenWidth === ScreenWidth.Desktop && (
        <DesktopView
          currentStep={currentStep}
          setStep={setStep}
          invitation={invitation}
          setStartDate={setStartDate}
          startDate={startDate}
          assetOwnerDetails={assetOwnerDetails}
          setAssetOwnerDetails={setAssetOwnerDetails}
          setPassword={setPassword}
          passwordIsEntered={passwordIsEntered}
          isCollectCompleted={isCollectCompleted}
          setIsCollectCompleted={setIsCollectCompleted}
          setCollectResult={setCollectResult}
        />
      )}
    </SmoothUp>
  );
};

export type ViewProps = {
  currentStep: number;
  setStep: (step: SignUpStep) => void;
  setStartDate: (startDate: Date) => void;
  startDate: Date;
  setPassword: (password: string) => void;
  invitation: GetAssetOwnerInvitationResponseDTO;
  assetOwnerDetails: AssetOwnerDetailsDTO;
  setAssetOwnerDetails: (assetOwnerDetails: AssetOwnerDetailsDTO) => void;
  passwordIsEntered: boolean;
  isCollectCompleted: boolean;
  setIsCollectCompleted: (isCollectCompleted: boolean) => void;
  setCollectResult: (collectResult: CollectResponseDTO) => void;
};

export const getStepStatus = (
  stepToAssignStatus: SignUpStep,
  currentStep: SignUpStep,
): StepStatus =>
  currentStep === SignUpStep.Landing || stepToAssignStatus > currentStep
    ? StepStatus.Upcoming
    : stepToAssignStatus === currentStep
      ? StepStatus.Current
      : StepStatus.Complete;

export type StepProps = {
  handleClick: () => void;
  isLoading?: boolean;
  invitation?: GetAssetOwnerInvitationResponseDTO;
};

export const getSteps = (currentStep: SignUpStep) => [
  {
    label: "Välkommen",
    status: getStepStatus(SignUpStep.Landing, currentStep),
  },
  {
    label: "Uppgifter",
    status: getStepStatus(SignUpStep.UserDetails, currentStep),
  },
  {
    label: "Elavtal",
    status: getStepStatus(SignUpStep.ElectricityContract, currentStep),
  },
  { label: "Villkor", status: getStepStatus(SignUpStep.Terms, currentStep) },
  {
    label: "Lösenord",
    status: getStepStatus(SignUpStep.ChoosePassword, currentStep),
  },
  {
    label: "E-legitimation",
    status: getStepStatus(SignUpStep.BankID, currentStep),
  },
];
