import { Role } from "enums/role.enum";

export type User = {
  id: number;
  email: string;
  role: Role;
  installerId?: number;
  distributorId?: number;
  assetOwnerId?: number;
  name: string;
};

export const isFlower = (role: Role) => role === Role.Flower;
export const isDistributor = (role: Role) =>
  [Role.DistributorAdmin, Role.DistributorUser].includes(role);
export const isInstaller = (role: Role) =>
  [Role.InstallerAdmin, Role.InstallerUser].includes(role);
export const isAssetOwner = (role: Role) => role === Role.AssetOwner;
export const isGuest = (role: Role) => role === Role.Guest;

export const getRoleName = (role: Role) => {
  switch (role) {
    case Role.AssetOwner:
      return "Asset owner";
    case Role.Flower:
      return "Flower";
    case Role.DistributorAdmin:
    case Role.InstallerAdmin:
      return "Administrator";
    case Role.DistributorUser:
    case Role.InstallerUser:
      return "User";
    default:
      return "Unknown type";
  }
};
