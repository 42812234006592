import { OpacityTransition } from "components/animations/SnapUp";
import { StepHeader } from "components/installationFlow/steps/StepHeader";
import { useTranslation } from "react-i18next";

export const PowerConfirmationStep = () => {
  const { t } = useTranslation();

  return (
    <OpacityTransition>
      <StepHeader>{t(`batteryOverview.Connect power cable`)}</StepHeader>
      <div className="flex justify-center">
        <div className="text-base w-9/12 font-normal text-gray-600">
          <p className="text-base text-gray-800">
            {t(`batteryOverview.The Flower Hub should now be connected to`)}:
          </p>
          <ul className="list-disc pl-8 text-base text-gray-800 pt-2">
            <li>{t(`batteryOverview.A power cable`)}</li>
            <li>
              {t(
                `batteryOverview.An Ethernet cable connecting to the same network as the inverter`,
              )}
            </li>
          </ul>
        </div>
      </div>
    </OpacityTransition>
  );
};
